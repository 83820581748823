import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ControlModel } from 'src/app/core/models/control.model';
import { CreateBuildingService } from 'src/app/core/services/create-building.service';
import { FieldOptionsService } from 'src/app/core/services/field-options.service';

@Component({
    selector: 'app-rte-property',
    templateUrl: './rte-property.component.html',
    styleUrls: ['./rte-property.component.scss']
})
export class RtePropertyComponent implements OnInit {
    @Input() controlInstance: ControlModel;
    @Input() isExpanded: boolean;
    public rtePropertyForm: FormGroup;
    private optionSubscription: Subscription;
    // field col names
    public fieldsColNames: any[] = [];

    constructor(
        private createBuildingService: CreateBuildingService,
        private fieldOptionsService: FieldOptionsService
    ) {}

    ngOnInit() {
        if (this.controlInstance.arrayGroup === 'table') {
            this.fieldsColNames = this.createBuildingService.dbColumnNameTable;
        } else {
            this.fieldsColNames = this.createBuildingService.dbColumnNameControl;
        }
        this.rtePropertyForm = new FormGroup({
            nodeName: new FormControl(
                this.controlInstance.attributes.node_name,
                Validators.required
            ),
            dbColumn: new FormControl(
                this.controlInstance.db_column,
                Validators.required
            ),
            required: new FormControl(this.controlInstance.attributes.required),
            readOnly: new FormControl(this.controlInstance.attributes.readonly),
            minValue: new FormControl(this.controlInstance.attributes.min),
            maxValue: new FormControl(this.controlInstance.attributes.max)
        });
        this.removeProperty();
        this.createBuildingService.buildingBlockGroup.addControl(
            'textProperty',
            this.rtePropertyForm
        );
        this.onChanges();
    }

    /**
     * Method is used to remove extra property
     */
    removeProperty() {
        delete this.controlInstance.languageInfo;
        delete this.controlInstance.attributes.name;
        delete this.controlInstance.source;
        // delete this.controlInstance.uuid;
        delete this.controlInstance.isEditable;
    }

    /**
     * This method is used to add listener on form group to detect any changes.
     */
    onChanges() {
        this.rtePropertyForm.valueChanges.subscribe((value) => {
            this.updateState();
        });
    }

    /**
     * This method is used to update state of the control in the common state.
     */
    updateState() {
        if (this.rtePropertyForm.valid) {
            this.controlInstance.attributes.node_name = this.rtePropertyForm.get(
                'nodeName'
            ).value;
            this.controlInstance.attributes.required = this.rtePropertyForm.get(
                'required'
            ).value;
            this.controlInstance.attributes.readonly = this.rtePropertyForm.get(
                'readOnly'
            ).value;
            this.controlInstance.attributes.min = this.rtePropertyForm.get(
                'minValue'
            ).value;
            this.controlInstance.attributes.max = this.rtePropertyForm.get(
                'maxValue'
            ).value;
            this.controlInstance.db_column = this.rtePropertyForm.get(
                'dbColumn'
            ).value;
            this.createBuildingService.buildingBlockGroup.removeControl(
                'textProperty'
            );
            this.createBuildingService.buildingBlockGroup.addControl(
                'textProperty',
                this.rtePropertyForm
            );
            this.createBuildingService.updateControl(this.controlInstance);
        }
    }

    ngOnDestroy(): void {
        this.controlInstance = null;
        if (this.optionSubscription) {
            this.optionSubscription.unsubscribe();
        }
    }
}
