import { Component } from '@angular/core';
import { Utils } from 'src/app/core/utils/utils';
import { FieldOptionsService } from 'src/app/core/services/field-options.service';
import {AuthService} from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'frw-dd';

  constructor(private fieldReportService: FieldOptionsService,
              private authService: AuthService ) {
    const utils = new Utils();
    // inject the service to get the initial data for options
    this.fieldReportService.options.subscribe(() => {
      // do nothing
    });
  }
}
