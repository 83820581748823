import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { Subscription } from 'rxjs';

import {BasePropertyComponent} from 'src/app/shared/components/property-panels/base/base-property.component';
import {ControlModel} from 'src/app/core/models/control.model';
import {CreateBuildingService} from 'src/app/core/services/create-building.service';
import { FieldOptionsService } from 'src/app/core/services/field-options.service';

@Component({
  selector: 'app-text-property',
  templateUrl: './text-property.component.html',
  styleUrls: ['./text-property.component.scss']
})
export class TextPropertyComponent extends BasePropertyComponent implements OnInit, OnDestroy {

  @Input() controlInstance: ControlModel;
  @Input() isExpanded: boolean;
  public textPropertyForm: FormGroup;
  private optionSubscription: Subscription;
  // field col names
  public fieldsColNames: any[] = [];

  constructor(private createBuildingService: CreateBuildingService, private fieldOptionsService: FieldOptionsService) {
    super();
  }

  ngOnInit() {
    super.selectFieldControl(this.createBuildingService);
    this.textPropertyForm = new FormGroup({
      nodeName: new FormControl(this.controlInstance.attributes.node_name, Validators.required),
      dbColumn: new FormControl(this.controlInstance.db_column, Validators.required),
      required: new FormControl(this.controlInstance.attributes.required),
      readOnly: new FormControl(this.controlInstance.attributes.readonly),
      minValue: new FormControl(this.controlInstance.attributes.min),
      maxValue: new FormControl(this.controlInstance.attributes.max)
    });
    this.removeProperty();
    this.createBuildingService.buildingBlockGroup.addControl('textProperty', this.textPropertyForm);
    this.onChanges();
  }

  /**
   * Method is used to remove extra property
   */
  removeProperty() {
    delete this.controlInstance.languageInfo;
    delete this.controlInstance.attributes.name;
    delete this.controlInstance.source;
    // delete this.controlInstance.uuid;
    delete this.controlInstance.isEditable;
  }

  /**
   * This method is used to add listener on form group to detect any changes.
   */
  onChanges() {
    this.textPropertyForm.valueChanges.subscribe(value => {
      this.updateState();
    });
  }


  /**
   * This method is used to update state of the control in the common state.
   */
  updateState() {
    if (this.textPropertyForm.valid) {
      this.controlInstance.attributes.node_name = this.textPropertyForm.get('nodeName').value;
      this.controlInstance.attributes.required = this.textPropertyForm.get('required').value;
      this.controlInstance.attributes.readonly = this.textPropertyForm.get('readOnly').value;
      this.controlInstance.attributes.min = this.textPropertyForm.get('minValue').value;
      this.controlInstance.attributes.max = this.textPropertyForm.get('maxValue').value;
      this.controlInstance.db_column = this.textPropertyForm.get('dbColumn').value;
      this.createBuildingService.buildingBlockGroup.removeControl('textProperty');
      this.createBuildingService.buildingBlockGroup.addControl('textProperty', this.textPropertyForm);
      this.createBuildingService.updateControl(this.controlInstance);
    }
  }

  ngOnDestroy(): void {
    this.controlInstance = null;
  }
}
