import { Injectable } from '@angular/core';
import { AuthService as CoreAuthService } from '@mosaic-apps/login-auth';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private authService: CoreAuthService) {}

  async isAuthenticated() {
    return !!(await this.authService.isAuthenticated());
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }
}
