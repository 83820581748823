import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatTabsModule,
  MatToolbarModule,
  MatMenuModule,
  MatIconModule,
  MatButtonToggleModule,
  MatDividerModule,
  MatCardModule,
  MatListModule,
  MatTreeModule,
  MatTableModule,
  MatPaginatorModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatSliderModule,
  MatGridListModule,
  MatSelectModule,
  MatDialogModule,
  MatDatepickerModule,
  MatSnackBarModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  MatSlideToggleModule
} from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DragDropModule } from '@angular/cdk/drag-drop';

const modules = [
  MatButtonModule,
  MatTabsModule,
  MatToolbarModule,
  MatMenuModule,
  MatIconModule,
  MatButtonToggleModule,
  MatDividerModule,
  MatCardModule,
  MatListModule,
  MatTableModule,
  MatPaginatorModule,
  MatFormFieldModule,
  MatInputModule,
  MatSnackBarModule,
  MatRadioModule,
  MatSliderModule,
  MatGridListModule,
  MatSelectModule,
  MatDialogModule,
  MatDatepickerModule,
  MatMomentDateModule,
  MatCheckboxModule,
  MatExpansionModule,
  DragDropModule,
  MatTableModule,
  MatTreeModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  MatSlideToggleModule
];

@NgModule({
  imports: modules,
  exports: modules
})
export class MaterialModule {}
