import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SectionVO} from 'src/app/core/vo/section.vo';
import {MenuModel} from 'src/app/core/models/menu.model';
import {DataModelService} from 'src/app/core/services/data-model.service';
import {Model} from 'src/app/core/enums/model.enum';
import {BuildingBlockModel} from 'src/app/core/models/building-block.model';


@Injectable({
  providedIn: 'root'
})
export class JsonLoaderService {

  // Holds the singleton instance of JsonLoaderService.
  public static instance: JsonLoaderService;

  constructor(private httpClient: HttpClient, private readonly dataModelService: DataModelService) {
    if (JsonLoaderService.instance) {
      throw new Error('Multiple instance of JsonLoaderService is not allowed');
    } else {
      JsonLoaderService.instance = this;
    }
  }

  loadConfigJson(): Promise<any> {
    return new Promise<SectionVO>((resolve: any) => {
      this.httpClient.get<SectionVO>('./assets/json/controls.json')
        .subscribe((data: any) => {
          const menu: MenuModel = this.dataModelService.getModel(Model.MENU_MODEL);
          menu.sectionArray = data.interfaces as Array<SectionVO>;
          resolve(true);
        });
    });
  }

  loadBuildingBlockJson() {
    return new Promise<Array<BuildingBlockModel>>((resolve: any) => {
      this.httpClient.get<Array<BuildingBlockModel>>('./assets/json/building-block.json')
        .subscribe((data: any) => {
          resolve(data);
        });
    });
  }
}
