import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Command} from "src/app/core/enums/command.enum";
import {ControlModel} from 'src/app/core/models/control.model';
import {BuildingBlockModel} from 'src/app/core/models/building-block.model';
import {Model} from 'src/app/core/enums/model.enum';
import {DataModelService} from 'src/app/core/services/data-model.service';
import {ReportTemplateModel} from 'src/app/core/models/report-template.model';
import {FormGroup} from "@angular/forms";
import {MessagingService} from "src/app/core/services/messaging.service";

@Injectable({
  providedIn: 'root'
})

export class CreateBuildingService {

  controlDataChange: BehaviorSubject<Array<ControlModel>> = new BehaviorSubject<Array<ControlModel>>([]);
  blockDataChange: BehaviorSubject<Array<BuildingBlockModel>> = new BehaviorSubject<Array<BuildingBlockModel>>([]);
  private controlModel: Array<ControlModel>;
  private tableModel: Array<ControlModel>;
  private buildingBlock: BuildingBlockModel;
  private reportTemplate: ReportTemplateModel;
  private _blockArray: Array<BuildingBlockModel>;
  public buildingBlockGroup: FormGroup;
  public reportBlockGroup: FormGroup;
  public dbColumnNameControl: Array<string>;
  public dbColumnNameTable: Array<string>;

  constructor(private dataModelService: DataModelService, private messageService: MessagingService) {
    this.reset();
  }

  get blockArray(): Array<BuildingBlockModel> {
    return this._blockArray;
  }

  set blockArray(value: Array<BuildingBlockModel>) {
    this._blockArray = value;
  }

  /**
   * This method is used to get the control array.
   */
  get controlModelArray(): Array<ControlModel> {
     return this.controlModel;
  }

  set controlModelArray(value: Array<ControlModel>) {
    this.controlModel = value;
  }

  /**
   * This method is used to get the control array.
   */
  get tableModelArray(): Array<ControlModel> {
    return this.tableModel;
  }

  set tableModelArray(value: Array<ControlModel>) {
    this.tableModel = value;
  }

  get buildingBlockModel(): BuildingBlockModel {
    return this.buildingBlock;
  }

  set buildingBlockModel(value: BuildingBlockModel) {
    this.buildingBlock = value;
  }

  get reportTemplateModel(): ReportTemplateModel {
    return this.reportTemplate;
  }

  set reportTemplateModel(value: ReportTemplateModel) {
    this.reportTemplate = value;
  }

  addDBTableFilter(filterValue: string) {
    this.dbColumnNameControl.filter((value) => {
      return value !== filterValue;
    });
  }

  /**
   * This method is used to update building block.
   * @param block: block is being updated
   */
  updateBuildingBlock(block: BuildingBlockModel) {
    this.buildingBlock = block;
    if (this.buildingBlock.children) {
      this.blockDataChange.next(this.buildingBlock.children);
    }
  }

  /**
   * This method send a message that shows that form values are valid or not
   * @param isValid
   */
  propertyValid(isValid: boolean) {
    this.messageService.postMessage(Command.PROPERTIES_VALID, isValid);
  }

  /**
   * This method checks that particular type of model is valid or not
   * @param type: name of the model
   */
  isValid(type: string): boolean {
    let valid: boolean;
    switch (type) {
      case 'BuildingBlock':
        valid = this.buildingBlockGroup.valid;
        break;
      case 'ReportModel':
        valid = this.reportBlockGroup.valid;
        break;
    }
    return valid;
  }

  /**
   * This method is used to update report template.
   * @param rt: Instance of report template model
   */
  updateReportTemplate(rt: ReportTemplateModel) {
    this.reportTemplate = rt;
  }

  /**
   * This method is used to handle and updating the control.
   * @param controlInstance: update control model.
   */
  updateControl(controlInstance: ControlModel) {
    const controlInstanceIndex = this.controlModel.findIndex(item => item.uuid === controlInstance.uuid);
    this.controlModel[controlInstanceIndex] = controlInstance;
    this.controlDataChange.next(this.controlModel);
  }

  /**
   * This method is used to delete control from control array
   */
  deleteControl(controlInstance: ControlModel) {
    const controlInstanceIndex = this.controlModel.findIndex(item => item.uuid === controlInstance.uuid);
    this.controlModel.splice(controlInstanceIndex, 1);
    this.controlDataChange.next(this.controlModel);
  }

  /**
   * This method is used to add control in control array
   */
  addControl(controlInstance: ControlModel) {
    if (controlInstance) {
      this.controlModel.push(controlInstance);
    }
  }

  /**
   * Method is used to reset/ initialize the property of service.
   */
  reset() {
    this.reportBlockGroup = new FormGroup({});
    this.buildingBlockGroup = new FormGroup({});
    this.controlModel = [];
    this.tableModel = [];
    this.buildingBlock = this.dataModelService.getModel(Model.BUILDING_BLOCK_MODEL);
    this.reportTemplate = new ReportTemplateModel();
    this.reportTemplate.isValid = false;
  }
}
