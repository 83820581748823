import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material';

import {ControlModel} from 'src/app/core/models/control.model';
import {MessagingService} from 'src/app/core/services/messaging.service';
import {BaseControl} from 'src/app/shared/components/controls/base/base-control';

@Component({
  selector: 'app-datepicker-control',
  templateUrl: './datepicker-control.component.html',
  styleUrls: ['./datepicker-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatepickerControlComponent extends BaseControl implements OnInit {

  @Input() disabled = true;
  @Input() controlModel: ControlModel;
  private date: Date;

  @Input()
  placeholder: string = 'Date Picker';

  constructor(private messageService: MessagingService, private dialog: MatDialog) {
    super(false, dialog);
    this.date = new Date();
  }

  ngOnInit() {
    super.init();
  }

  remove(): void {
  }
 
  OnDestroy() {
    super.destroy();
  }

}
