import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { Subscription } from 'rxjs';

import {BasePropertyComponent} from 'src/app/shared/components/property-panels/base/base-property.component';
import {MessagingService} from 'src/app/core/services/messaging.service';
import {CreateBuildingService} from 'src/app/core/services/create-building.service';
import { FieldOptionsService } from 'src/app/core/services/field-options.service';

@Component({
  selector: 'app-date-property',
  templateUrl: './date-property.component.html',
  styleUrls: ['./date-property.component.scss']
})
export class DatePropertyComponent extends BasePropertyComponent implements OnInit, OnDestroy {

  @Input() isExpanded: boolean;
  public dateFormGroup: FormGroup;
  private optionSubscription: Subscription;

  constructor(private messageService: MessagingService, private createBuildingService: CreateBuildingService, private fieldOptionsService: FieldOptionsService) {
    super();
  }

  ngOnInit() {
    super.selectFieldControl(this.createBuildingService);
    if (this.controlInstance.arrayGroup === 'table') {
      this.fieldsColNames = this.createBuildingService.dbColumnNameTable;
    } else {
      this.fieldsColNames = this.createBuildingService.dbColumnNameControl;
    }
    this.dateFormGroup = new FormGroup({
      dbColumn: new FormControl(this.controlInstance.db_column, Validators.required),
      required: new FormControl(this.controlInstance.attributes.required),
      readOnly: new FormControl(this.controlInstance.attributes.readonly),
      nodeName: new FormControl(this.controlInstance.attributes.node_name, Validators.required),
    });
    this.removeProperty();
    this.createBuildingService.buildingBlockGroup.addControl('dateProperty', this.dateFormGroup);
    this.onChanges();
  }

  /**
   * Method is used to remove extra property
   */
  removeProperty() {
    delete this.controlInstance.languageInfo;
    delete this.controlInstance.source;
    // delete this.controlInstance.uuid;
    delete this.controlInstance.isEditable;
    delete this.controlInstance.attributes.max;
    delete this.controlInstance.attributes.min;
    delete this.controlInstance.attributes.name;
  }

  /**
   * This method is used to add subscriber to the form group for any change in the value and will update that change in
   * service.
   */
  onChanges() {
    this.dateFormGroup.valueChanges.subscribe(value => {
      this.updateState();
    });
  }

  /**
   * This method is used to update state of the control in the common state.
   */
  updateState() {
    this.controlInstance.attributes.node_name = this.dateFormGroup.get('nodeName').value;
    this.controlInstance.db_column = this.dateFormGroup.get('dbColumn').value;
    this.controlInstance.attributes.readonly = this.dateFormGroup.get('readOnly').value;
    this.controlInstance.attributes.required = this.dateFormGroup.get('required').value;
    this.controlInstance.isEditable = this.dateFormGroup.get('isEditable').value;
    if (this.dateFormGroup.valid) {
      this.createBuildingService.updateControl(this.controlInstance);
      this.createBuildingService.buildingBlockGroup.removeControl('dateProperty');
      this.createBuildingService.buildingBlockGroup.addControl('dateProperty', this.dateFormGroup);
    }

  }

  ngOnDestroy(): void {
    this.controlInstance = null;
  }
}
