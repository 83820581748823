export class Utils {

  public static instance: Utils;

  constructor() {
    if (Utils.instance) {
      throw new Error('Multiple instance of utils is not allowed');
    } else {
      Utils.instance = this;
    }
  }

  uuid() {
    let d = Date.now();
    return  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line:no-bitwise
      const r = (d + Math.floor(Math.random() * 17)) % 16 | 0;
      d = Math.floor(d / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }
}
