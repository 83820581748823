import {Control} from "src/app/core/enums/control.enum";
import {BuildingBlockModel} from "src/app/core/models/building-block.model";
import {ControlModel} from "src/app/core/models/control.model";

export class JsonSerializer {

  mapCompositeBlockJson(data: Array<BuildingBlockModel>) {
    return data.map(child => {
        return {
          id: child.id,
          version: child.version,
          blockDisplayName: child.blockDisplayName,
          child_items: (child.children && child.children.length > 0) ? this.mapCompositeBlockJson(child.children) : null
        }
      });
  }

  /**
   * This method is used to covert/parse string in composite block json format, after converting it returns JSON string.
   * @param data: string json which need to be converted
   */
  parseCompositeBlockJson(data: BuildingBlockModel): string {
    const {children, ...compositeBlockObject} = data;
    compositeBlockObject['child_items'] = this.mapCompositeBlockJson(data.children);
    compositeBlockObject['display_num'] = '1.';
    compositeBlockObject['type'] = Control.COMPOSITE_BLOCK;
    compositeBlockObject.version = data.version;
    delete compositeBlockObject.items;
    return JSON.stringify(this.removeBlankValue(compositeBlockObject));
  }

  /**
   * This method is used to convert/ parse provided json string for the RTE.
   * @param data
   */
  parseRTEJson(data: BuildingBlockModel): string {
    const {items, ...rteBlock} = data;
    let buttonArray = [];
    buttonArray = this.extractActionArray(data.items[0].controls, buttonArray);
    rteBlock['items'] = [{
      type: data.items[0].type,
      action_controls: buttonArray
    }];
    rteBlock.version = data.version;
    return JSON.stringify(rteBlock);
  }

  /**
   * This method is used to parse block json, it move button type control in action controls and the return the converted string.
   * @param data
   */
  parseFormBlockJson(data: BuildingBlockModel): string {
    let buttonArray = [];
    buttonArray = this.extractActionArray(data.items[0].controls, buttonArray);
    const {children,...block} = data;
    block['child_items'] = children;
    block.items[0].actionControls = buttonArray;
    const convertedBlock = this.removeBlankValue(block);
    convertedBlock.version = data.version;
    delete convertedBlock.items[0].tableInfo;
    delete convertedBlock.uuid;
    return JSON.stringify(convertedBlock);
  }

  /**
   * This method is used to parse Grid block json, it move button type control in action controls and the return the converted string.
   * @param data
   */
  parseGridJson(data: BuildingBlockModel): string {
    let buttonArray = [];
    let tableButtonArray = [];
    buttonArray = this.extractActionArray(data.items[0].controls, buttonArray);
    tableButtonArray = this.extractActionArray(data.items[0].tableInfo.columns, tableButtonArray);
    data.items[0].actionControls = buttonArray;
    const {building_block_name, children, items, ...bGridBlock} = data;
    bGridBlock['items'] = items;
    bGridBlock['building_block_name'] = building_block_name;
    bGridBlock['child_items'] = children;
    const convertedBlock = this.removeBlankValue(bGridBlock);
    convertedBlock.version = data.version;
    delete convertedBlock.uuid;
    return JSON.stringify(convertedBlock);
  }

  /**
   * This method is used to remove blank/null/ undefined values from provided object and return a new object.
   * @param obj
   */
  removeBlankValue(obj: any) {
    let newObj:any = {};
    Object.keys(obj).forEach((prop) => {
      if (obj[prop]) {
        newObj[prop] = obj[prop];
      }
    });
    return newObj;
  }

  /**
   * Method is used to extract button control from the provided array
   * @param data
   * @param buttonArray
   */
  extractActionArray(data:Array<ControlModel>, buttonArray:Array<any>) {
    data.forEach((value, index) => {
      if(value.type === Control.BUTTON) {
        buttonArray.push(data.splice(index,1)[0]);
      }
    });

    return buttonArray.map( button => {
      if (button['action_type'] === 'user') {
        return button;
      } else {
        return {
          attributes: {
            actionIdentifier: button.attributes.node_name,
            name: button.db_column
          },
          type: button.type
        };
      }
    });
  }

  /**
   * This method is used to covert/parse string in building block format, after converting it returns JSON string.
   * @param data: string json which need to be converted
   */
  parseBlockJson(data: BuildingBlockModel): string {
    let convertedJson;
    switch (data.items[0].type) {
      case Control.COMPOSITE_BLOCK:
        convertedJson =  this.parseCompositeBlockJson(data);
        break;
      case Control.RTE:
        convertedJson =  this.parseRTEJson(data);
        break;
      case Control.GRID:
        convertedJson =  this.parseGridJson(data);
        break;
      default:
        convertedJson = this.parseFormBlockJson(data);
    }
    return convertedJson;
  }

  /**
   * This method is used to covert/parse string in report template json format, after converting it returns JSON string.
   * @param data: string json which need to be converted
   */
  parseReportJson(data: string): string {
    const {building_block_list, ...reportObject} = JSON.parse(data);
    reportObject.building_block_list = building_block_list.map(item => {
      const {children, ...data} = item;
      data.child_items = children;
      return data;
    });
    delete reportObject.isValid;
    return JSON.stringify(this.removeBlankValue(reportObject));
  }
}
