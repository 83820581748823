import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import { Subscription } from 'rxjs';

import {BasePropertyComponent} from 'src/app/shared/components/property-panels/base/base-property.component';
import {ControlModel} from 'src/app/core/models/control.model';
import {CreateBuildingService} from 'src/app/core/services/create-building.service';
import { FieldOptionsService } from 'src/app/core/services/field-options.service';

@Component({
  selector: 'app-file-property',
  templateUrl: './file-property.component.html',
  styleUrls: ['./file-property.component.scss']
})
export class FilePropertyComponent extends BasePropertyComponent implements OnInit, OnDestroy {

  @Input() isExpanded: boolean;
  public filePropertyGroup: FormGroup;
  private optionSubscription: Subscription;

  constructor(private createBuildingService: CreateBuildingService, private fieldOptionsService: FieldOptionsService) {
    super();
  }

  ngOnInit() {
    super.selectFieldControl(this.createBuildingService);
    this.filePropertyGroup = new FormGroup({
      dbColumn: new FormControl(this.controlInstance.db_column, Validators.required),
      isEditable: new FormControl(this.controlInstance.isEditable),
      required: new FormControl(this.controlInstance.attributes.required),
      readOnly: new FormControl(this.controlInstance.attributes.readonly),
      max: new FormControl(this.controlInstance.attributes.max),
      nodeName: new FormControl(this.controlInstance.attributes.node_name, Validators.required),
    });
    this.removeProperty();
    this.createBuildingService.buildingBlockGroup.addControl('fileProperty', this.filePropertyGroup);
    this.onChanges();
  }

  /**
   * Method is used to remove extra property
   */
  removeProperty() {
    delete this.controlInstance.languageInfo;
    delete this.controlInstance.source;
    // delete this.controlInstance.uuid;
    delete this.controlInstance.attributes.min;
    delete this.controlInstance.attributes.name;

  }

  /**
   * This method is used to add listener on form group to detect any changes.
   */
  onChanges() {
    this.filePropertyGroup.valueChanges.subscribe(value => {
      this.updateState();
    });
  }

  updateGroup() {
    this.controlInstance.attributes.node_name = this.filePropertyGroup.get('nodeName').value;
    this.controlInstance.attributes.required = this.filePropertyGroup.get('required').value;
    this.controlInstance.attributes.readonly = this.filePropertyGroup.get('readOnly').value;
    this.controlInstance.attributes.max = this.filePropertyGroup.get('max').value;
    this.controlInstance.db_column = this.filePropertyGroup.get('dbColumn').value;
    this.controlInstance['includeInHTML'] = this.filePropertyGroup.get('isEditable').value;
  }
  /**
   * This method is used to update state of the control in the common state.
   */
  updateState() {
    this.updateGroup();
    if (this.filePropertyGroup.valid) {
      this.createBuildingService.updateControl(this.controlInstance);
      this.createBuildingService.buildingBlockGroup.removeControl('fileProperty');
      this.createBuildingService.buildingBlockGroup.addControl('fileProperty', this.filePropertyGroup);
    }
  }

  ngOnDestroy(): void {
    this.controlInstance = null;
  }
}
