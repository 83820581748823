import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material';

import {BasePreviewComponent} from 'src/app/shared/components/preview/base/base-preview.component';
import {MessagingService} from 'src/app/core/services/messaging.service';


@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent extends BasePreviewComponent implements OnInit {

  constructor(private messageService: MessagingService, private dialog: MatDialog) {
    super(false, dialog);
  }

  ngOnInit(): void {
    this.title = this.controlModel.attributes.node_name;
    super.init();
  }

  /**
   * This is click handler for this, it will call base class function to notify\
   * other interested candidate about the event.
   * @param event - Instance of mouse event.
   */
  onSelectItem(event: MouseEvent) {
    event.stopPropagation();
    super.onSelect(this.messageService);
  }
}
