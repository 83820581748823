import { Component, Inject, OnInit , OnDestroy} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';

import { BuildingBlockModel } from 'src/app/core/models/building-block.model';
import { FieldOptionsService } from 'src/app/core/services/field-options.service';

@Component({
  selector: 'app-report-block-config-dialog',
  templateUrl: './report-block-config-dialog.component.html',
  styleUrls: ['./report-block-config-dialog.component.scss']
})
export class ReportBlockConfigDialogComponent implements OnInit, OnDestroy {
  public blockFormGroup: FormGroup;
  private buildingBlock: BuildingBlockModel;
  // dynamic options
  public nodeNames: any[] = [];
  public nodeFullPath: any[] = [];
  private optionSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ReportBlockConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BuildingBlockModel,
    private fieldOptionsService: FieldOptionsService
  ) {
    this.buildingBlock = data;
  }

  ngOnInit() {
    this.optionSubscription = this.fieldOptionsService.options.subscribe(options => {
      this.nodeNames = options.node_names;
      this.nodeFullPath = options.node_full_path;
    });
    this.blockFormGroup = new FormGroup({
      nodeName: new FormControl(null, Validators.required),
      nodePath: new FormControl(null, Validators.required)
    });
  }

  /**
   * This method is used to fetch values from form group and update the building block in service.
   */
  updateBuildingBlock() {
    this.buildingBlock.node_name = this.blockFormGroup.get('nodeName').value;
    this.buildingBlock.node_full_path = this.blockFormGroup.get(
      'nodePath'
    ).value;
  }

  /**
   * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
   * Event Handlers --
   * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
   */

  /**
   * This method is used to handle cancel clicked on configuration dialog, this will take back user to home page.
   */
  cancelClicked() {
    this.dialogRef.close(null);
  }

  /**
   * This method is used to save the dialog data entered by user
   */
  save() {
    if (this.blockFormGroup.valid && this.blockFormGroup.touched) {
      this.updateBuildingBlock();
      this.dialogRef.close(this.buildingBlock);
    } else {
      Object.keys(this.blockFormGroup.controls).forEach(field => {
        const control = this.blockFormGroup.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  /**
   * Performs custom clean-up, invoked immediately after a component instance is destroyed
   */
  ngOnDestroy() {
    this.optionSubscription.unsubscribe();
  }
}
