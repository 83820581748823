export class AttributesVO {
  public readonly = false;
  public required = false;
  public identifier: string;
  // disabled--
  public min: string;
  public max: string;
  public name: string;
  // tslint:disable-next-line: variable-name
  public node_name: string;
  // tslint:disable-next-line: variable-name
  public node_full_path: string;
}
