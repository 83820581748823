import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { API_URL } from 'src/app/core/core.constant';
import { CreateBuildingService } from 'src/app/core/services/create-building.service';

@Injectable({
    providedIn: 'root'
})
export class FieldOptionsService {
    // empty response object
    private response = {
        building_block_db_name: [],
        node_full_path: [],
        node_names: [],
        report_type: []
    };

    // options to get the data
    public options = new BehaviorSubject<any>(this.response);

    /**
     * Inject dependencies and fetch the options.
     * @param httpClient HttpClient service
     */
    constructor(
        private httpClient: HttpClient,
        private buildingService: CreateBuildingService
    ) {
        // this.fetchOptions();
    }

    /**
     * Get all the field options i.e. node_name, node_full_path, etc.
     */
    private fetchOptions() {
        this.httpClient.get<any>(API_URL.FIELDS_OPTIONS).subscribe(
            (data) => {
                if (data.success) {
                    if (data.options.fields_col_name) {
                        this.buildingService.dbColumnNameControl = data.options.business_hook_name = data.options.fields_col_name.map(
                            (item) => `fn${this.convertToPascalCase(item)}`
                        );
                    } else {
                        this.buildingService.dbColumnNameControl = data.options.business_hook_name = [];
                    }
                    this.options.next(data.options);
                } else {
                    this.options.next(this.response);
                }
            },
            (error) => {
                // stream empty response in case of any error
                this.options.next(this.response);
            }
        );
    }

    /**
     * Convert the string to pascal case
     * @param str String to be converted
     */
    convertToPascalCase(str: string) {
        return `${str}`
            .replace(new RegExp(/[-_]+/, 'g'), ' ')
            .replace(new RegExp(/[^\w\s]/, 'g'), '')
            .replace(
                new RegExp(/\s+(.)(\w+)/, 'g'),
                ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
            )
            .replace(new RegExp(/\s/, 'g'), '')
            .replace(new RegExp(/\w/), (s) => s.toUpperCase());
    }
}
