// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // apiUrl: 'http://localhost:4000',
    apiUrl:
     'https://c6pfhpysl9.execute-api.eu-central-1.amazonaws.com/test_auth/v1/auth' /**NEW QA INFRA */
    // 'https://c7hfj5rui9.execute-api.eu-central-1.amazonaws.com/test_auth/v1/auth'  /* New DEV Infra*/
    // 'https://4332d1wsui.execute-api.eu-central-1.amazonaws.com/test_auth/v1/auth' /** PRD New Infra 3.0.1*/
    // tslint:disable-next-line: max-line-length
    // 'https://dq98mktpbd.execute-api.eu-central-1.amazonaws.com/test/v1/auth'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
