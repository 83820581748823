import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(public snackBar: MatSnackBar) {
  }

  success(message: string, options?: { delay?: number, dismissible?: boolean }) {
    this.snackBar.open(message, '', {
      duration: 1000
    });
  }

  danger(message: string, options?: { delay?: number, dismissible?: boolean }) {
    this.snackBar.open(message, 'X', {panelClass: ['error'],  duration: 3000});
  }
}
