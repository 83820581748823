import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  forwardRef
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

/**
 * @title Option groups autocomplete
 */
@Component({
  selector: 'app-option-select',
  templateUrl: './option-select.component.html',
  styleUrls: ['./option-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OptionSelectComponent),
      multi: true
    }
  ]
})
export class OptionSelectComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  // create form group
  formGroup: FormGroup = new FormGroup({
    inputText: new FormControl()
  });

  // visible options
  currentOptions: any[];

  interval: any;

  @Input()
  options: any[];

  @Input()
  userInput = false;

  @Input()
  required = true;

  @Input()
  placeholder: string;

  @Input()
  errorMessage: string;

  @Output()
  valueChanged = new EventEmitter<any>();

  @Input()
  private _value: string;
  get value() {
    return this._value;
  }
  set value(val: string) {
    this._value = val;
    this.filterOptions(val);
    this.onChange(val);
    this.onTouched(val);

    // set initial value
    if (!this.formGroup.get('inputText').value && val) {
      this.formGroup.get('inputText').setValue(val);
    }

    // emitt value change event
    this.valueChanged.emit(val);
  }

  /**
   * Change handler. Do nothing.
   */
  onChange: any = () => {};

  /**
   * Touch handler. Do nothing.
   */
  onTouched: any = () => {};

  ngOnInit() {
    // set default filter
    this.filterOptions('');

    // listen for change in text input
    this.formGroup.get('inputText').valueChanges.subscribe(value => {
      this.value = value;
    });
  }

  /**
   * Keep a reference to the onChange
   * @param fn callback function passed by the forms API
   */
  registerOnChange(fn) {
    this.onChange = fn;
  }

  /**
   * Keep a reference to the onTouched
   * @param fn callback function passed by the forms API
   */
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  /**
   * Basic setter that the forms API is going to use
   * @param value Item value
   */
  writeValue(value) {
    if (value) {
      this.value = value;
      console.log('Write value =', value);

      this.formGroup.get('inputText').setValue(value);
    }
  }

  /**
   * Filter the options
   * @param value Option text to be filtered
   */
  filterOptions(value: string) {
    if (!(value === null || value === undefined) && this.options) {
      value = value.toString();
      value = value.toLowerCase();
      this.currentOptions = this.options.filter(text => {
        text = text.toLowerCase();
        return text.includes(value);
      });
    }
  }

  /**
   * Handle text change event.
   * @param event Text input event object
   */
  onTextChange(event) {
    // delay the validation
    this.interval = setTimeout(() => {
      if (!this.userInput) {
        const {
          target: { value }
        } = event;
        const itemExist = this.options.find(item => {
          return value === item;
        });
        if (!itemExist) {
          this.formGroup.patchValue({
            inputText: ''
          });
        }
      }
    }, 100);
  }

  /**
   * Performs custom clean-up, invoked immediately after a component instance is destroyed
   */
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
