import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ReportTemplateModel } from 'src/app/core/models/report-template.model';

import { FieldOptionsService } from 'src/app/core/services/field-options.service';

@Component({
    selector: 'app-report-configuration-dialog',
    templateUrl: './report-configuration-dialog.component.html',
    styleUrls: ['./report-configuration-dialog.component.scss']
})
export class ReportConfigurationDialogComponent implements OnInit {
    public readonly reportTemplate: ReportTemplateModel;
    public reportFormGroup: FormGroup;
    // dynamic options
    public reportType: any[] = [];
    public nodeFullPath: any[] = [];
    private optionSubscription: Subscription;

    constructor(
        public dialogRef: MatDialogRef<ReportConfigurationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router,
        private fieldOptionsService: FieldOptionsService
    ) {
        this.reportTemplate = data;
    }

    ngOnInit() {
        this.optionSubscription = this.fieldOptionsService.options.subscribe(
            (options) => {
                this.reportType = options.report_type;
                this.nodeFullPath = options.node_full_path;
            }
        );

        this.reportFormGroup = new FormGroup({
            nodePath: new FormControl(null, Validators.required),
            reportType: new FormControl(null, Validators.required),
            type: new FormControl(null, Validators.required)
        });
    }

    /**
     * This method is used to update form value in local instance of report template.
     */
    updateBuildingBlock() {
        this.reportTemplate.report_type = this.reportFormGroup.get(
            'reportType'
        ).value;
        this.reportTemplate.node_full_path = this.reportFormGroup.get(
            'nodePath'
        ).value;
        this.reportTemplate.type = this.reportFormGroup.get('type').value;
    }

    /**
     * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
     * Event Handlers --
     * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
     */

    /**
     * This method is used to handle cancel clicked on configuration dialog, this will take back user to home page.
     */
    cancelClicked() {
        this.dialogRef.close(null);
        this.router.navigate(['/']);
    }

    /**
     * This method is used to save the dialog data entered by user
     */
    save() {
        if (this.reportFormGroup.valid) {
            this.updateBuildingBlock();
            this.dialogRef.close(this.reportTemplate);
        } else {
            Object.keys(this.reportFormGroup.controls).forEach((field) => {
                const control = this.reportFormGroup.get(field);
                control.markAsTouched({ onlySelf: true });
            });
        }
    }

    /**
     * Performs custom clean-up, invoked immediately after a component instance is destroyed
     */
    ngOnDestroy() {
        this.optionSubscription.unsubscribe();
    }
}
