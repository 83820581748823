import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-publish-confirmation-dialog',
  templateUrl: './publish-confirmation-dialog.component.html',
  styleUrls: ['./publish-confirmation-dialog.component.scss']
})
export class PublishConfirmationDialogComponent implements OnInit {
  public currentVersion: any;
  public newVersion: any;
  constructor(
    public dialogRef: MatDialogRef<PublishConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public version: any
  ) {}

  ngOnInit() {
    if (isNaN(this.version) || this.version === 0) {
      this.currentVersion = 'Draft';
      this.newVersion = 1;
      this.newVersion = 'version ' + this.newVersion.toFixed(1);
    } else {
      this.currentVersion = 'version ' + this.version.toFixed(1);
      this.newVersion = (this.version + 1).toFixed(1);
    }
  }

  save() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
