import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { API_URL } from 'src/app/core/core.constant';
import {LoaderService} from "src/app/core/services/loader.service";

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
  
  callsInProgress: number = 0;

  constructor(public loaderService: LoaderService) {}

  /**
   * The function is used to intercept the request and response and log the error in case of any.
   *
   */
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // do not show loader if getting data for field options
    if (req.url !== API_URL.FIELDS_OPTIONS) {
      this.loaderService.showLoader();
      this.callsInProgress++;
    }
    return next.handle(req).pipe(
      finalize(() => {
        this.callsInProgress--;
        if(this.callsInProgress === 0) {
          // hide loader when all calls are completed
          this.loaderService.hideLoader();
        }
      })
    );
  }
}
