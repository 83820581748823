import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserOperationService } from '@mosaic-apps/user-auth';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  title = 'Create Form Builder';
  activeRoute: string = null;
  userName = '';
  userId = null;
  tokenFlag = false;

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.logout();
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userAuth: UserOperationService,
    private loginService: AuthService
  ) {}

  ngOnInit(): void {
    this.addRouterListener();
    // Todo _ need to take user name from user service
    this.userName = localStorage.getItem('userName');
    // this.getUserId();
  }

  /**
   * Get the user id.
   * This is required, if user would like to it's own profile.
   */
  getUserId() {
    this.tokenFlag = true;
    this.userAuth.fetchGuid()
    .then(
      response => {
        if (response.success) {
          localStorage.setItem('gid', response.data.gid);
          localStorage.setItem('region', response.data.region);
          localStorage.setItem('regionId', response.data.regionId);
          localStorage.setItem('userName', response.data.userName);
          localStorage.setItem('domainName', response.data.domainName);
          localStorage.setItem('userFunctionalities', response.data.loggedInUserFunctionalities);
          localStorage.setItem('adminRoleRegion', response.data.adminRoleRegion);
          this.userId = response.data.id;
          this.userName = localStorage.getItem('userName');
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  /**
   * This method is used to add listener on router whenever route change event is occurred,
   */
  addRouterListener() {
    this.router.events.subscribe((routerParams: any) => {
      let routerUrl = routerParams.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        routerUrl = routerUrl.replace(/\//g, ' ');
        if (routerUrl.includes('callback')) {
          this.activeRoute = '';
        } else if (routerUrl.trim() === 'home') {
          if (!this.tokenFlag) {
            this.getUserId();
          }
          this.activeRoute = null;
        } else {
          this.activeRoute = routerUrl;
        }
      }
    });
  }

  logout() {
    this.loginService.logout();
  }
  /**
   * This method is used to handle click on siemens logo
   * @param event- Mouse event
   */
  onHomeClicked(event: MouseEvent) {
    this.router.navigate(['../home'], { relativeTo: this.route });
  }

  /**
   * This method is used to handle the logout, from here we will try to close the web page, and
   * on successful attempt we will call logout service
   */
  logOutClicked() {
    confirm('Are you sure want to leave this page?');
    window.open('', '_parent', '').close();
  }

  /**
   * Go to user profile.
   */
  goToUserProfile() {
    if (this.userId) {
      this.userAuth.fetchUsersDetails({ userId: this.userId });
      this.router.navigate(['../manage/users/profile']);
    } else {
      alert('Error: User is not logged in.');
      this.logOutClicked();
    }
  }
}
