import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss']
})
export class TemplatePreviewComponent implements OnInit, OnChanges, OnDestroy {

  @Input() title = '';
  @Input() control = [];

  @ViewChild('container', {read: ViewContainerRef, static: true}) viewContainerRef: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.renderControls();
  }

  renderControls() {
    this.removeAllDynamicComponent();
  }

  /**
   * This method is used to remove component added at run time.
   */
  removeAllDynamicComponent() {
    this.control.forEach((value, index) => {
      this.viewContainerRef.remove(index);
    });
  }

  ngOnDestroy(): void {
    this.removeAllDynamicComponent();
    this.control = null;
  }
}
