import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatCheckboxChange, MatSlideToggleChange} from '@angular/material';
import {BasePropertyComponent} from 'src/app/shared/components/property-panels/base/base-property.component';
import {BuildingBlockModel} from 'src/app/core/models/building-block.model';
import {CreateBuildingService} from 'src/app/core/services/create-building.service';
import {BusinessHookModel} from 'src/app/core/models/business-hook-model';
import {FieldOptionsService} from 'src/app/core/services/field-options.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-building-block-property',
  templateUrl: './building-block-property.component.html',
  styleUrls: ['./building-block-property.component.scss']
})
export class BuildingBlockPropertyComponent extends BasePropertyComponent implements OnInit, OnChanges, OnDestroy {
  @Input() controlModelInstance: BuildingBlockModel;
  @Input() isDisabled: boolean;
  @Input() isExpanded: boolean;
  public buildingBlockPropertyGroup: FormGroup;
  public showBusinessHooks = false;
  public businessHooks: FormArray;

  // dynamic options
  public nodeNames: any[] = [];
  public dbTableNames: any[] = [];
  public nodeFullPath: any[] = [];
  public identifiers: any[] = [];
  public businessHookNames: any[] = [];
  private optionSubscription: Subscription;

  public hooksMode: string[] = ['sync', 'async'];
  public hooksType: string[] = ['lambda', 'report'];

  constructor(
    private createBuildingService: CreateBuildingService,
    private fieldOptionsService: FieldOptionsService
  ) {
    super();
  }

  ngOnInit() {
    this.optionSubscription = this.fieldOptionsService.options.subscribe(
      options => {
        this.nodeNames = options.node_names;
        this.dbTableNames = options.building_block_db_name;
        this.nodeFullPath = options.node_full_path;
        this.identifiers = options.building_block_identifier;
        this.businessHookNames = options.business_hook_name;
      }
    );
    const version =
      this.controlModelInstance.version === 0
        ? 'Draft'
        : this.controlModelInstance.version;
    this.buildingBlockPropertyGroup = new FormGroup({
      version: new FormControl({ value: null, disabled: true }, Validators.required),
      blockName: new FormControl({value: null, disabled: this.isDisabled}, Validators.required),
      displayName: new FormControl({ value: null, disabled: this.isDisabled }, Validators.required),
      description: new FormControl({ value: null, disabled: this.isDisabled }, Validators.required),
      nodeName: new FormControl(null, Validators.required),
      identifier: new FormControl(null, Validators.required),
      nodePath: new FormControl({ value: null, disabled: this.isDisabled}, Validators.required),
      dbTable: new FormControl({value: null, disabled: this.isDisabled}, Validators.required),
      validation: new FormControl(null),
      pre_load: new FormControl(null),
      post_save: new FormControl(null),
      pre_save: new FormControl(null),
    });
    this.forceUpdate();
    this.buildingBlockPropertyGroup.addControl('businessHooks', this.businessHooks);
    this.createBuildingService.buildingBlockGroup.addControl('buildingBlockProperty', this.buildingBlockPropertyGroup);
    this.onChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.buildingBlockPropertyGroup) {
      this.forceUpdate();
    }
  }

  /**
   * This will update the view
   */
  forceUpdate() {
    this.buildingBlockPropertyGroup.get('version').setValue(this.controlModelInstance.version);
    this.buildingBlockPropertyGroup.get('blockName').setValue(this.controlModelInstance.building_block_name);
    this.buildingBlockPropertyGroup.get('displayName').setValue(this.controlModelInstance.blockDisplayName);
    this.buildingBlockPropertyGroup.get('description').setValue(this.controlModelInstance.blockDescription);
    this.buildingBlockPropertyGroup.get('nodeName').setValue(this.controlModelInstance.node_name);
    this.buildingBlockPropertyGroup.get('identifier').setValue(this.controlModelInstance.identifier);
    this.buildingBlockPropertyGroup.get('nodePath').patchValue(this.controlModelInstance.node_full_path);
    this.buildingBlockPropertyGroup.get('dbTable').patchValue(this.controlModelInstance.db_table);
    this.buildBusinessHooks();
  }

  /**
   * This method is used to add business hooks.
   */
  buildBusinessHooks() {
    this.businessHooks = new FormArray([]);
    if(this.controlModelInstance.business_hooks) {
      const keys = Object.keys(this.controlModelInstance.business_hooks);
      this.showBusinessHooks = keys.length === 0 ? false : true;
      if (this.showBusinessHooks) {
        let nameParts: string[];
        let name: string;
        keys.forEach(key => {
          nameParts = key.split('_');
          name = nameParts[0];
          name = name[0].toUpperCase() + name.slice(1);
          if (nameParts.length > 1) {
            name = name + nameParts[1][0].toUpperCase() + nameParts[1].slice(1);
          }
          this.buildingBlockPropertyGroup.get(key).patchValue({checked: true});
          this.businessHooks.push(this.createBusinessHookGroup(name, this.controlModelInstance.business_hooks[key]));
        });
      }
    }
  }

  /**
   * This method is used to update state of the control in the common state.
   */
  updateState() {
    let version: any = this.buildingBlockPropertyGroup.get('version').value;
    if (version === 'Draft') {
      version = 0;
    }
    this.controlModelInstance.version = version;
    // this.controlModelInstance.name = this.buildingBlockPropertyGroup.get('name').value;
    this.controlModelInstance.node_name = this.buildingBlockPropertyGroup.get(
      'nodeName'
    ).value;
    if (
      this.showBusinessHooks &&
      this.businessHooks &&
      this.businessHooks.length > 0
    ) {
      this.controlModelInstance.business_hooks = this.fetchBusinessHooks();
    }
    if (this.isValid(this.buildingBlockPropertyGroup)) {
      this.refreshFormGroup();
      this.createBuildingService.updateBuildingBlock(this.controlModelInstance);
    }
  }

  /**
   * This method is used to refresh form group so that proper validation can occur.
   */
  refreshFormGroup() {
    this.createBuildingService.buildingBlockGroup.removeControl(
      'buildingBlockProperty'
    );
    this.createBuildingService.buildingBlockGroup.addControl(
      'buildingBlockProperty',
      this.buildingBlockPropertyGroup
    );
  }

  /**
   * This method is used to fetch all the block data
   */
  fetchBusinessHooks(): any {
    const businessHooks = {};
    let title;
    for (let i = 0; i <= this.businessHooks.length - 1; i++) {
      if (this.isValid(this.businessHooks.controls[i])) {
        const businessHook = new BusinessHookModel();
        title = this.businessHooks.controls[i].get('title').value;
        businessHook.name = this.businessHooks.controls[i].get('hookName').value;
        businessHook.mode = this.businessHooks.controls[i].get('hookMode').value;
        businessHook.sendData = this.businessHooks.controls[i].get('hookSendData').value;
        businessHook.type = this.businessHooks.controls[i].get('hookType').value;
        title = title.replace(' ', '_');
        title = title.toLowerCase();
        businessHooks[title] = businessHook;
      }
    }
    return businessHooks;
  }

  /**
   * This method is used to check thar form group is valid or not.
   * @param form
   */
  isValid(form: any) {
    let valid;
    if (form.valid) {
      valid = true;
    } else {
      Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        control.markAsTouched({onlySelf: true});
      });
      this.createBuildingService.propertyValid(false);
    }
    return valid;
  }

  /**
   * Method is used to create a for group for validators
   * @param name
   * @param options
   */
  createBusinessHookGroup(name: string, options: any = {}) {
    return new FormGroup({
      title: new FormControl(name),
      hookName: new FormControl(options.name, Validators.required),
      hookMode: new FormControl(options.mode, Validators.required),
      hookSendData: new FormControl(options.sendData),
      hookType: new FormControl(options.type, Validators.required)
    });
  }

  /**
   * This method is used to add listener on form group to detect any changes.
   */
  onChanges() {
    this.buildingBlockPropertyGroup.valueChanges.subscribe(value => {
      this.updateState();
    });
  }

  /**
   * This method is handler for checkbox selection
   * @param event
   */
  onBusinessHookSelect(event: MatSlideToggleChange) {
    this.showBusinessHooks = event.checked;
  }

  /**
   * Method is used to add validation hooks
   * @param name
   */
  addValidationHook(name: string) {
    this.businessHooks = this.buildingBlockPropertyGroup.get(
      'businessHooks'
    ) as FormArray;
    this.businessHooks.push(this.createBusinessHookGroup(name));
    this.refreshFormGroup();
  }

  /**
   * This method is used to find index in form array
   * @param name
   */
  getIndex(name: string): number {
    const length: number = this.businessHooks.length - 1;
    let index;
    for (let i = 0; i <= length; i++) {
      if (this.businessHooks.controls[i].get('title').value === name) {
        index = i;
        break;
      }
    }
    return index;
  }

  /**
   * This method is used to remove form group of asked business hook
   * @param name
   */
  removeValidationHook(name: string) {
    this.businessHooks.removeAt(this.getIndex(name));
    this.refreshFormGroup();
  }

  /**
   * This method is handler for checkbox selection
   * @param event
   * @param name
   */
  onBusinessHookTypeSelect(event: MatCheckboxChange, name: string) {
    if (event.checked) {
      this.addValidationHook(name);
    } else {
      this.removeValidationHook(name);
    }
  }

  /**
   * Performs custom clean-up, invoked immediately after a component instance is destroyed
   */
  ngOnDestroy() {
    this.optionSubscription.unsubscribe();
  }
}
