import { Model } from 'src/app/core/enums/model.enum';
import { MenuModel } from 'src/app/core/models/menu.model';
import {ModelBase} from 'src/app/core/models/base/model-base';
import {ConfigurationModel} from 'src/app/core/models/configuration.model';
import {BuildingBlockModel} from 'src/app/core/models/building-block.model';

export class ModelStore {

  // Variable that contain the models array
  private models: Array<{id: string, class: any}> = [
    {id: Model.BUILDING_BLOCK_MODEL, class: BuildingBlockModel},
    {id: Model.CONFIGURATION_MODEL, class: ConfigurationModel},
    {id: Model.MENU_MODEL, class: MenuModel}
  ];

  // variable that acts as a mapping between the model and its id
  private modelsMap: Map<string, ModelBase>;

  constructor() {
    this.modelsMap = new Map<string, ModelBase>();
  }

  /**
   * The function is used to get the model based on the id
   *
   */
  public getModel(id: string): ModelBase {
    let model: ModelBase = this.modelsMap.get(id);
    if (!model) {
      model = this.createModel(id);
    }
    return model;
  }
  /**
   * This function is used to create the fresh instance of model type. If requested type is not present
   * in the model list, the it will return null else it will return the requested model type instance.
   *
   */
  public createModel(id: string): any {
    const modelInfo: any = this.getModelInformation(id);
    let modelInstance: ModelBase = null;
    if (modelInfo) {
      modelInstance = new modelInfo.class(id);
      this.saveModel(id, modelInstance);
    }
    return modelInstance;
  }

  /**
   * The function is used to save the model based on id
   *
   */
  public saveModel(id: string, modelInstance: any) {
    this.modelsMap.set(id, modelInstance);
  }

  /**
   * The function is used to retrieve the model information from the set of model list based on the id provided
   *
   */
  private getModelInformation(id: string) {
    return this.models.filter((model: any) => model.id === id)[0];
  }

  /**
   * This method is responsible for returning disposing member variables listeners.
   */
  public disposeById(id: string): void {
    if (id !== Model.MENU_MODEL) {
      this.modelsMap.delete(id);
    }
  }
}
