import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FlatTreeControl} from "@angular/cdk/tree";
import {MatSnackBar, MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material";
import {Guid} from "guid-typescript";
import {Subscription} from "rxjs";
import {Command} from "src/app/core/enums/command.enum";
import {BlockItemFlatNode} from "src/app/core/models/block-item-flat-node";
import {BuildingBlockModel} from "src/app/core/models/building-block.model";
import {ReportTemplateModel} from "src/app/core/models/report-template.model";
import {BlockListNodeService} from "src/app/core/services/block-list-node.service";
import {BuildingBlockService} from "src/app/core/services/building-block.service";
import {CreateBuildingService} from "src/app/core/services/create-building.service";
import {MessagingService} from "src/app/core/services/messaging.service";
import {ReportTemplateService} from "src/app/core/services/report-template.service";

@Component({
  selector: 'app-composite-block-container',
  templateUrl: './composite-block-container.component.html',
  styleUrls: ['./composite-block-container.component.scss']
})
export class CompositeBlockContainerComponent implements OnInit {
  isExpanded = true;
  public selectedIndex: number;
  public blockList: Array<BuildingBlockModel> = [];
  public treeControl: FlatTreeControl<BlockItemFlatNode>;
  public dataSource: MatTreeFlatDataSource<BuildingBlockModel, BlockItemFlatNode>;
  public reportTemplateModel: ReportTemplateModel;

  private blockSelection: Subscription;
  private controlSavedSelection: Subscription;
  private buildBlockFlattener: MatTreeFlattener<BuildingBlockModel, BlockItemFlatNode>;
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<BlockItemFlatNode, BuildingBlockModel>();
  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<BuildingBlockModel, BlockItemFlatNode>();
  // @ts-ignore
  @ViewChild('emptyItem') emptyItem: ElementRef;
  /* Drag and drop */
  dragNodeMenu: any;

  constructor(private reportService: ReportTemplateService, private buildingBlockService: BuildingBlockService,
              private messageService: MessagingService, private blockNodeService: BlockListNodeService,
              private snackBar: MatSnackBar, private createBuildingService: CreateBuildingService) {
    this.reportTemplateModel = this.createBuildingService.reportTemplateModel;
    this.selectedIndex = -1;
    this.initializeTree();
    blockNodeService.dataChange.subscribe(data => {
      this.dataSource.data = [];
      this.dataSource.data = data;
    });
  }

  getLevel = (node: BlockItemFlatNode) => node.level;

  isExpandable = (node: BlockItemFlatNode) => node.expandable;

  getChildren = (node: BuildingBlockModel): BuildingBlockModel[] => node.children;

  // tslint:disable-next-line:variable-name
  hasChild = (_: number, _nodeData: BlockItemFlatNode) => _nodeData.expandable;

  initializeTree() {
    this.buildBlockFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<BlockItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.buildBlockFlattener);
  }

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: BuildingBlockModel, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.uuid
      ? existingNode
      : new BlockItemFlatNode();
    flatNode.item = node.name;
    flatNode.level = level;
    flatNode.uuid = node.uuid;
    flatNode.expandable = (node.children && node.children.length > 0);
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  ngOnInit() {
    this.buildingBlockService.fetchBuildingBlock(null).then((result) => {
      this.blockList = result.blocks.map((value: any) => {
        const bl: BuildingBlockModel = JSON.parse(value.data);
        bl.id = value.id;
        return bl;
      });
      this.reportTemplateModel.building_block_list = [];
    });

    this.addSubscription();
    setTimeout(() => {
      this.deselectControl(null);
    }, 100);
  }

  /**
   * This method is used to add subscription so that we can track for the selection of block.
   */
  addSubscription() {
    this.blockSelection = this.messageService.subscribe(Command.BLOCK_SELECTED, (event) => {
      // this.selectedIndex = event.payload.id;
    });
    this.controlSavedSelection = this.messageService.subscribe(Command.CONTROL_SAVED, (event) => {
      this.updateModel(event);
    });
  }

  /**
   * Method is used to update concern model with the updated model.
   * @param event: contains updated object
   */
  updateModel(event: any) {
    const toBeModel: ReportTemplateModel | BuildingBlockModel = event.payload;
    if (toBeModel instanceof ReportTemplateModel) {
      this.reportTemplateModel = toBeModel;
      this.reportTemplateModel.building_block_list = JSON.parse(JSON.stringify(this.blockList));
    } else {
      this.reportTemplateModel.building_block_list.forEach(value => {
        if (value.id === toBeModel.id) {
          value = toBeModel;
        }
        return value;
      });
    }
  }

  /**
   * This method is used to handle building block which is being dropped.
   * @param event drag event
   */
  onItemDropped(event: DragEvent) {
    let data: any = event.dataTransfer.getData('draggedItem');
    data = JSON.parse(data);
    data.uuid = Guid.create();
    this.reportTemplateModel.building_block_list.push(data);
    this.blockNodeService.initialize(this.reportTemplateModel.building_block_list);
  }

  /**
   * This method is used to handle the item of list which is dragged from left side.
   * @param data Dragged item
   */
  onDragStartList(data) {
    this.dragNodeMenu = data;
  }

  /**
   * This method is used to handle to drag end , so that we can reset the <code>dragNodeMenu<code>.
   * @param event: instance of drag event
   */
  onDragEndList(event) {
    this.dragNodeMenu = null;
  }

  /**
   * Method is used to clear selected component.
   * @param event: instance of mouse event
   */
  deselectControl(event: MouseEvent) {
    if (event) {
      event.stopImmediatePropagation();
      this.selectedIndex = -1;
    }
    this.messageService.postMessage(Command.BLOCK_SELECTED, {index: -1, report: this.reportTemplateModel});
  }

  /**
   * Method is used to create report template.
   */
  createReport() {
    this.reportService.createReport(JSON.stringify(this.reportTemplateModel)).then(result => {
      this.reportTemplateModel.id = result.id;
      this.snackBar.open('Report Template Created', 'Dismiss', {duration: 2000});
    });
  }

  /**
   * Method is used to update report template.
   */
  editReport() {
    this.reportService.updateReport(this.reportTemplateModel.id, JSON.stringify(this.reportTemplateModel)).then(result => {
      console.log(result);
      this.snackBar.open('Report Template Updated', 'Dismiss', {duration: 2000});
    });
  }

  /**
   * This method is used to reset the progress.
   * @param event instance of mouse event
   */
  onCancel(event: MouseEvent) {
    this.reportTemplateModel = new ReportTemplateModel();
    this.treeControl = null;
    this.dataSource = null;
    this.initializeTree();
    this.blockNodeService.initialize(this.reportTemplateModel.building_block_list);
  }

  /**
   * This method is used to Save/ Update created template, if current reported template contains Id then
   * update call will be placed otherwise create report template will be created.
   */
  onSave() {
    if (!this.reportTemplateModel.id) {
      this.createReport();
    } else {
      this.editReport();
    }
  }
}
