import { AttributesVO } from 'src/app/core/vo/attributes.vo';
import {SourceItemVo} from "src/app/core/vo/source-item.vo";

export class ControlModel {
  type: string;
  uuid: string;
// tslint:disable-next-line: variable-name
  db_column: string;
  isEditable: boolean;
  attributes: AttributesVO = new AttributesVO();
  languageInfo: Array<any>;
  source: SourceItemVo = new SourceItemVo();
  arrayGroup: string;
}
