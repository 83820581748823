import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { BasePropertyComponent } from 'src/app/shared/components/property-panels/base/base-property.component';
import { ControlModel } from 'src/app/core/models/control.model';
import { MessagingService } from 'src/app/core/services/messaging.service';
import { CreateBuildingService } from 'src/app/core/services/create-building.service';
import { FieldOptionsService } from 'src/app/core/services/field-options.service';

@Component({
  selector: 'app-checkbox-property',
  templateUrl: './checkbox-property.component.html',
  styleUrls: ['./checkbox-property.component.scss']
})
export class CheckboxPropertyComponent extends BasePropertyComponent
  implements OnInit, OnDestroy {
  @Input() isExpanded: boolean;
  public checkboxFormGroup: FormGroup;
  private optionSubscription: Subscription;

  constructor( private messageService: MessagingService, private createBuildingService: CreateBuildingService,
    private fieldOptionsService: FieldOptionsService) {
    super();
  }

  ngOnInit() {
    super.selectFieldControl(this.createBuildingService);
    this.checkboxFormGroup = new FormGroup({
      dbColumn: new FormControl(this.controlInstance.db_column,Validators.required),
      required: new FormControl(this.controlInstance.attributes.required),
      readOnly: new FormControl(this.controlInstance.attributes.readonly),
      nodeName: new FormControl(this.controlInstance.attributes.node_name, Validators.required),
    });
    this.removeProperty();
    this.createBuildingService.buildingBlockGroup.addControl('checkBoxProperty',this.checkboxFormGroup);
    this.onChanges();
  }

  /**
   * Method is used to remove extra property
   */
  removeProperty() {
    delete this.controlInstance.isEditable;
    delete this.controlInstance.source;
    // delete this.controlInstance.uuid;
    delete this.controlInstance.languageInfo;
    delete this.controlInstance.attributes.name;
    delete this.controlInstance.attributes.min;
    delete this.controlInstance.attributes.max;
  }

  /**
   * This method is used to add new options for the checkbox
   * @param cb
   * @param cbInput
   * @param i
   */
  // checkBoxValueChange(cb: any, cbInput: any, i: number) {
  //   this.controlInstance.source.options[i].value = cb.checked;
  //   this.controlInstance.source.options[i].displayName = cbInput.value;
  //   this.updateState();
  // }


  /**
   * This method is used to add listener on form group to detect any changes.
   */
  onChanges() {
    this.checkboxFormGroup.valueChanges.subscribe(value => {
      this.updateState();
    });
  }

  /**
   * This method is used to update state of the control in the common state.
   */
  updateState() {
    if (this.checkboxFormGroup.valid) {
      this.controlInstance.attributes.node_name = this.checkboxFormGroup.get('nodeName').value;
      this.controlInstance.db_column = this.checkboxFormGroup.get('dbColumn').value;
      this.controlInstance.attributes.readonly = this.checkboxFormGroup.get('readOnly').value;
      this.controlInstance.attributes.required = this.checkboxFormGroup.get('required').value;

      this.createBuildingService.buildingBlockGroup.removeControl('checkBoxProperty');
      this.createBuildingService.buildingBlockGroup.addControl('checkBoxProperty',this.checkboxFormGroup);
      this.createBuildingService.updateControl(this.controlInstance);
    } else {
      // TODO: Do we need to show error ??
    }
  }

  /**
   * This method is used to add option in control instance.
   */
  // addOption(option) {
  //   if (option.value) {
  //     this.controlInstance.source.options.push({
  //       displayName: option.value,
  //       value: 'false'
  //     });
  //     const control = new FormControl(option.value, Validators.required);
  //     (this.checkboxFormGroup.get('optionArray') as FormArray).controls.push(control);
  //     this.updateState();
  //   }
  // }

  ngOnDestroy(): void {
    this.controlInstance = null;
  }
}
