import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef, MatRadioChange} from '@angular/material';
import {Router} from '@angular/router';

import {Control} from 'src/app/core/enums/control.enum';
import {BuildingBlockModel} from 'src/app/core/models/building-block.model';
import {BuildingBlockItemModel} from 'src/app/core/models/building-block-item.model';
import {CreateBuildingService} from "src/app/core/services/create-building.service";
import {FieldOptionsService} from 'src/app/core/services/field-options.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-building-block-selector-dialog',
  templateUrl: './building-block-selector-dialog.component.html',
  styleUrls: ['./building-block-selector-dialog.component.scss']
})
export class BuildingBlockSelectorDialogComponent implements OnInit, OnDestroy {
  public readonly blockItem: BuildingBlockItemModel;
  public blockFormGroup: FormGroup;
  public blockArray: Array<BuildingBlockModel>;
  private readonly buildingBlock: BuildingBlockModel;

  // dynamic options
  public nodeNames: any[] = [];
  public dbTableNames: any[] = [];
  public nodeFullPath: any[] = [];
  public identifiers: any[] = [];
  private optionSubscription: Subscription;

  constructor(public dialogRef: MatDialogRef<BuildingBlockSelectorDialogComponent>, private buildingService: CreateBuildingService,
              @Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private fieldOptionsService: FieldOptionsService) {
    this.blockItem = new BuildingBlockItemModel();
    this.buildingBlock = this.data.bb;
    this.blockArray = this.data.blockArray.filter(value => {
      return value.type !== Control.COMPOSITE_BLOCK;
    });
    this.buildingBlock.items.push(this.blockItem);
  }

  ngOnInit() {
    this.optionSubscription = this.fieldOptionsService.options.subscribe(options => {
      this.nodeNames = options.node_names;
      this.dbTableNames = this.buildingService.dbColumnNameControl;
      this.nodeFullPath = options.node_full_path;
      this.identifiers = options.building_block_identifier;
    });
    this.blockFormGroup = new FormGroup({
      blockType: new FormControl(null, Validators.required),
      blockName: new FormControl(null, Validators.required),
      blockDisplayName: new FormControl(null, Validators.required),
      blockDescription: new FormControl(null, Validators.required),
      identifier: new FormControl(null, Validators.required),
      nodeName: new FormControl(null, Validators.required),
      nodePath: new FormControl(null, Validators.required),
      dbTable: new FormControl(null, Validators.required),
      blockArray: new FormControl()
    });
  }

  /**
   * This method is used to fetch values from form group and update the building block in service.
   */
  updateBuildingBlock() {
    this.buildingBlock.building_block_name = this.blockFormGroup.get('blockName').value;
    this.buildingBlock.identifier = this.blockFormGroup.get('identifier').value;
    this.buildingBlock.node_name = this.blockFormGroup.get('nodeName').value;
    this.buildingBlock.node_full_path = this.blockFormGroup.get('nodePath').value;
    this.buildingBlock.db_table = this.blockFormGroup.get('dbTable').value;
    this.buildingBlock.blockDisplayName = this.blockFormGroup.get('blockDisplayName').value;
    this.buildingBlock.blockDescription = this.blockFormGroup.get('blockDescription').value;
    if (this.blockItem.type === Control.GRID) {
      this.buildingBlock.items[0].tableInfo.dbTable = this.blockFormGroup.get('dbTableColumn').value;
    }
    this.buildingBlock.children = this.blockFormGroup.get('blockArray').value;
  }

  /**
   * This method add additional form control depending on user selection
   */
  addValidators() {
    if (this.blockItem.type === Control.GRID) {
      this.blockFormGroup.addControl('dbTableColumn', new FormControl(null, Validators.required));
    } else {
      this.blockFormGroup.removeControl('dbTableColumn');
    }
  }

  /**
   * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
   * Event Handlers --
   * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
   */

  /**
   * Method is called when value of option select is changes, it will also make sure that selected item
   * is removed from CreateBuildingBlock service array.
   */
  valueChanged(event: any) {
    this.buildingService.addDBTableFilter(event);
  }

  /**
   * This method is used to handle cancel clicked on configuration dialog, this will take back user to home page.
   */
  cancelClicked() {
    this.dialogRef.close(null);
    this.router.navigate(['/']);
  }

  /**
   * This method is used to save the dialog data entered by user
   */
  save() {
    if (this.blockFormGroup.valid) {
      this.updateBuildingBlock();
      this.dialogRef.close(this.buildingBlock);
    } else {
      Object.keys(this.blockFormGroup.controls).forEach(field => {
        const control = this.blockFormGroup.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  /**
   * This method is called when user select a option
   * @param event: instance of Mat select change instance.
   */
  onSelectionChange(event: MatRadioChange) {
    this.blockItem.type = event.value;
    this.addValidators();
  }

  /**
   * Performs custom clean-up, invoked immediately after a component instance is destroyed
   */
  ngOnDestroy() {
    this.optionSubscription.unsubscribe();
  }
}
