import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material';

import {BaseControl} from 'src/app/shared/components/controls/base/base-control';
import {ControlModel} from 'src/app/core/models/control.model';
import {MessagingService} from 'src/app/core/services/messaging.service';

@Component({
  selector: 'app-lookup-control',
  templateUrl: './lookup-control.component.html',
  styleUrls: ['./lookup-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LookupControlComponent extends BaseControl implements OnInit {
  @Input() controlModel: ControlModel;

  constructor(private messageService: MessagingService, private dialog: MatDialog) {
    super(false, dialog);
  }

  ngOnInit() {
    super.init();
  }

  remove(): void {
  }

  onSelectItem() {
    super.onSelect(this.messageService);
  }

  OnDestroy() {
    super.destroy();
  }
}
