import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { API_URL } from 'src/app/core/core.constant';
import {JsonSerializer} from "../utils/json-serializer";

@Injectable({
  providedIn: 'root'
})

export class ReportTemplateService {
  private jsonSerializer: JsonSerializer;
  constructor(private httpClient: HttpClient) {
    this.jsonSerializer = new JsonSerializer();
  }

  /**
   * This method is used to create build report. This will send a template json to server.
   * @param templateJson Json string for the report template
   */
  createReport(templateJson: string): Promise<any> {
    templateJson = this.jsonSerializer.parseReportJson(templateJson);
    return new Promise<any>((resolve: any) => {
      this.httpClient
        .post<any>(API_URL.REPORT, { data: templateJson })
        .subscribe((data: any) => {
          resolve(data);
        });
    });
  }

  /**
   * Method is used to update template json for the provided id.
   * @param id: id for the template json
   * @param templateJson: json string which need to update
   */
  updateReport(id: string, templateJson: string): Promise<any> {
    templateJson = this.jsonSerializer.parseReportJson(templateJson);
    return new Promise<any>((resolve: any) => {
      this.httpClient
        .post<any>(API_URL.REPORT, { id: Number(id), data: templateJson })
        .subscribe((data: any) => {
          resolve(data);
        });
    });
  }

  /**
   * Method is used to fetch all the reports if Id is not provided, if id is provided that report json
   * will be fetched.
   * @param id string value for the report json which need to be fetched.
   */
  fetchReport(id: string) {
    let promise: Promise<any>;
    if (id) {
      promise = new Promise<any>((resolve: any) => {
        this.httpClient
          .get<any>(`${API_URL.REPORT}?id=${Number(id)}`)
          .subscribe((data: any) => {
            resolve(data);
          });
      });
    } else {
      promise = new Promise<any>((resolve: any) => {
        this.httpClient.get<any>(API_URL.REPORTS).subscribe((data: any) => {
          resolve(data);
        });
      });
    }
    return promise;
  }
}
