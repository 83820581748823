import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material';

import {BaseControl} from 'src/app/shared/components/controls/base/base-control';
import {MessagingService} from 'src/app/core/services/messaging.service';
import {ControlModel} from 'src/app/core/models/control.model';
import {ListItemVO} from 'src/app/core/vo/list-item.vo';

@Component({
  selector: 'app-checkbox-control',
  templateUrl: './checkbox-control.component.html',
  styleUrls: ['./checkbox-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxControlComponent extends BaseControl implements OnInit {

  @Input() controlModel: ControlModel;
  @Input() options: Array<ListItemVO>;

  constructor(private messageService: MessagingService, private dialog: MatDialog) {
    super(false, dialog);
  }

  ngOnInit() {
    super.init();
  }

  remove(): void {
  }

  onSelectItem() {
    super.onSelect(this.messageService);
  }

  OnDestroy() {
    super.destroy();
  }

}
