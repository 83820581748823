import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Guid} from 'guid-typescript';
import {MatSelectChange} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {ConfigurationModel} from 'src/app/core/models/configuration.model';
import {ControlModel} from 'src/app/core/models/control.model';
import {DataModelService} from 'src/app/core/services/data-model.service';
import {Model} from 'src/app/core/enums/model.enum';
import {AttributesVO} from 'src/app/core/vo/attributes.vo';
import {ListItemVO} from 'src/app/core/vo/list-item.vo';


@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit, OnDestroy {

  public configObject: ConfigurationModel;
  public formGroup: FormGroup;
  @Input() controlModel: ControlModel;
  @Input() configPopover: any;
  constructor(private dataModelService: DataModelService,
              private formBuilder: FormBuilder) { }

  /**
   * The method is called on angular Init
   */
  ngOnInit() {
    this.configObject = this.dataModelService.getModel(Model.CONFIGURATION_MODEL);
    this.configObject.controlModel = this.controlModel;
    this.configObject.selectedLanguage = this.configObject.languageInfo[0];
    this.formGroup = this.formBuilder.group({
      nodeName : [null, Validators.required],
      dbColumn: [null, Validators.required],
      language: [null, Validators.required]
    });
  }

  /**
   * The function is called when select option is changed
   */
  onSelectionChange(event: MatSelectChange) {
    this.configObject.selectedLanguage = event.value;
  }

  /**
   * The function is called when save is clicked
   */
  save() {
    if (this.formGroup.valid) {
      this.configObject.controlModel.languageInfo = this.configObject.languageInfo;
      // this.dialogRef.close(this.configObject.controlModel);
    } else {
      Object.keys(this.formGroup.controls).forEach(field => {
        const control = this.formGroup.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  /**
   * The function is called when cancel is clicked
   */
  dismissPopover() {
    this.configPopover.close();
  }

  /**
   * The function is called to replace all the numeric character with the alphabet ones
   */
  onTextChange(attributes: any, prop: string) {
    let value: string = attributes[prop];
    const regExp = /\d/g;
    value = value.replace(regExp, '');
    attributes[prop] = value;
  }

  /**
   * The function is called to replace all the string character with the numeric ones
   */
  onChange(attributes: AttributesVO, prop: string) {
    let value: string = attributes[prop];
    const regExp = /[A-Za-z]/g;
    value = value.replace(regExp, '');
    attributes[prop] = value;
  }

  /**
   * The function is used to add options to the select control
   */
  addOption() {
    const listItem: ListItemVO = new ListItemVO();
    this.configObject.activeListItem = listItem;
    listItem.displayName = Guid.create().toString();
    this.configObject.controlModel.source.options.push(listItem);
  }

  /**
   * The method is called when on destroy is called
   */
  ngOnDestroy(): void {
    this.configObject = null;
    this.dataModelService.disposeById(Model.CONFIGURATION_MODEL);
  }

}
