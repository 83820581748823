import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { Subscription } from 'rxjs';

import {BasePropertyComponent} from 'src/app/shared/components/property-panels/base/base-property.component';
import {ControlModel} from 'src/app/core/models/control.model';
import {CreateBuildingService} from 'src/app/core/services/create-building.service';
import { FieldOptionsService } from 'src/app/core/services/field-options.service';


@Component({
  selector: 'app-lookup-property',
  templateUrl: './lookup-property.component.html',
  styleUrls: ['./lookup-property.component.scss']
})
export class LookupPropertyComponent extends BasePropertyComponent implements OnInit, OnDestroy  {

  @Input() controlInstance: ControlModel;
  @Input() isExpanded: boolean;
  public selectPropertyGroup: FormGroup;
  public dataInfoTypes = ['function', 'view'];
  public dataInfoSelectedType = 'function';
  private optionSubscription: Subscription;
  // field col names
  public fieldsColNames: any[] = [];

  constructor(private createBuildingService: CreateBuildingService, private fieldOptionsService: FieldOptionsService) {
    super();
  }

  ngOnInit() {
    const dataInfoName = this.controlInstance.source.dataInfo.name;
    const dataInfoType = this.controlInstance.source.dataInfo.type;
    this.dataInfoSelectedType = dataInfoType;
    super.selectFieldControl(this.createBuildingService);
    this.selectPropertyGroup = new FormGroup({
      dbColumn: new FormControl(this.controlInstance.db_column, Validators.required),
      required: new FormControl(this.controlInstance.attributes.required),
      readOnly: new FormControl(this.controlInstance.attributes.readonly),
      nodeName: new FormControl(this.controlInstance.attributes.node_name, Validators.required),
      min: new FormControl(this.controlInstance.attributes.min, Validators.required),
      max: new FormControl(this.controlInstance.attributes.max, Validators.required),
      dataInfo: new FormControl(dataInfoName, Validators.required),
      dataInfoType: new FormControl(dataInfoType, Validators.required),
    });
    this.removeProperty();
    this.createBuildingService.buildingBlockGroup.addControl('selectPropertyGroup', this.selectPropertyGroup);
    this.onChanges();
  }

  /**
   * Method is used to remove extra property
   */
  removeProperty() {
    delete this.controlInstance.languageInfo;
    // delete this.controlInstance.uuid;
    delete this.controlInstance.isEditable;
    delete this.controlInstance.attributes.name;
    delete this.controlInstance.source.options;
    delete this.controlInstance.source.params;
  }

  /**
   * This method is used to add listener on form group to detect any changes.
   */
  onChanges() {
    this.selectPropertyGroup.valueChanges.subscribe(value => {
      this.updateState();
    });
  }

  /**
   * This method is used to update state of the control in the common state.
   */
  updateState() {
    this.controlInstance.db_column = this.selectPropertyGroup.get('dbColumn').value;
    this.controlInstance.attributes.node_name = this.selectPropertyGroup.get('nodeName').value;
    this.controlInstance.attributes.readonly = this.selectPropertyGroup.get('readOnly').value;
    this.controlInstance.attributes.required = this.selectPropertyGroup.get('required').value;
    this.controlInstance.attributes.min = this.selectPropertyGroup.get('min').value;
    this.controlInstance.attributes.max = this.selectPropertyGroup.get('max').value;
    this.controlInstance.source.dataInfo.name = this.selectPropertyGroup.get('dataInfo').value;
    this.controlInstance.source.dataInfo.type = this.selectPropertyGroup.get('dataInfoType').value;
    if (this.selectPropertyGroup.valid) {
      this.createBuildingService.buildingBlockGroup.removeControl('selectPropertyGroup');
      this.createBuildingService.buildingBlockGroup.addControl('selectPropertyGroup', this.selectPropertyGroup);
      this.createBuildingService.updateControl(this.controlInstance);
    }
  }


  ngOnDestroy(): void {
    this.controlInstance = null;
  }

}
