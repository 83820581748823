import {NgModule} from '@angular/core';
import {NgbPopoverModule, NgbTabsetModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MaterialModule} from './material/material.module';
import {RouterModule} from '@angular/router';

import {AppDragDropDirective} from 'src/app/shared/directive/app-drag-drop.directive';
import {FormContainerComponent} from 'src/app/shared/components/container/form-container/form-container.component';
import {ConfigurationDialogueComponent} from 'src/app/shared/components/dialog/configuration-dialogue/configuration-dialogue.component';
import {GridContainerComponent} from 'src/app/shared/components/container/grid-container/grid-container.component';
import {TextControlComponent} from 'src/app/shared/components/controls/text-control/text-control.component';
import {DatepickerControlComponent} from 'src/app/shared/components/controls/datepicker-control/datepicker-control.component';
import {SelectControlComponent} from 'src/app/shared/components/controls/select-control/select-control.component';
import {FileControlComponent} from 'src/app/shared/components/controls/file-control/file-control.component';
import {LookupControlComponent} from 'src/app/shared/components/controls/lookup-control/lookup-control.component';
import {ButtonControlComponent} from 'src/app/shared/components/controls/button-control/button-control.component';
import {CheckboxControlComponent} from 'src/app/shared/components/controls/checkbox-control/checkbox-control.component';
import {PopoverComponent} from 'src/app/shared/components/popover/popover.component';
import {TemplatePreviewComponent} from 'src/app/shared/components/container/template-preview/template-preview.component';
import {TextPreviewComponent} from 'src/app/shared/components/preview/text-preview/text-preview.component';
import {DatePreviewComponent} from 'src/app/shared/components/preview/date-preview/date-preview.component';
import {SelectPreviewComponent} from 'src/app/shared/components/preview/select-preview/select-preview.component';
import {CheckboxPreviewComponent} from 'src/app/shared/components/preview/checkbox-preview/checkbox-preview.component';
import {ButtonPreviewComponent} from 'src/app/shared/components/preview/button-preview/button-preview.component';
import {FilePreviewComponent} from 'src/app/shared/components/preview/file-preview/file-preview.component';
import {TextPropertyComponent} from 'src/app/shared/components/property-panels/text-property/text-property.component';
import {DatePropertyComponent} from 'src/app/shared/components/property-panels/date-property/date-property.component';
import {FilePropertyComponent} from 'src/app/shared/components/property-panels/file-property/file-property.component';
import {ButtonPropertyComponent} from 'src/app/shared/components/property-panels/button-property/button-property.component';
import {SelectPropertyComponent} from 'src/app/shared/components/property-panels/select-property/select-property.component';
import {CheckboxPropertyComponent} from 'src/app/shared/components/property-panels/checkbox-property/checkbox-property.component';
import {OptionSelectComponent} from 'src/app/shared/components/option-select/option-select.component';
// tslint:disable-next-line:max-line-length
import {BuildingBlockPropertyComponent} from 'src/app/shared/components/property-panels/building-block-property/building-block-property.component';
// tslint:disable-next-line:max-line-length
import { ReportTemplatePropertyComponent } from 'src/app/shared/components/property-panels/report-template-property/report-template-property.component';
import { LookupPreviewComponent } from 'src/app/shared/components/preview/lookup-preview/lookup-preview.component';

import { LookupPropertyComponent } from 'src/app/shared/components/property-panels/lookup-property/lookup-property.component';
// tslint:disable-next-line:max-line-length
import { BuildingBlockSelectorDialogComponent } from 'src/app/shared/components/dialog/building-block-selector-dialog/building-block-selector-dialog.component';
import {CompositeBlockContainerComponent} from "src/app/shared/components/container/composite-block-container/composite-block-container.component";
import {TreeBlockComponent} from "src/app/shared/components/preview/tree-block/tree-block.component";
import { ReportBlockConfigDialogComponent } from 'src/app/shared/components/dialog/report-block-config-dialog/report-block-config-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { ReportConfigurationDialogComponent } from 'src/app/shared/components/dialog/report-configuration-dialog/report-configuration-dialog.component';
import { PublishConfirmationDialogComponent } from './components/dialog/publish-confirmation-dialog/publish-confirmation-dialog.component';
import { RteBlockContainerComponent } from './components/container/rte-block-container/rte-block-container.component';
import { RtePropertyComponent } from './components/property-panels/rte-property/rte-property.component';
import { RtePreviewComponent } from './components/preview/rte-preview/rte-preview.component';
import { TextAreaPreviewComponent } from './components/preview/text-area-preview/text-area-preview.component';
import { TextAreaPropertyComponent } from './components/property-panels/text-area-property/text-area-property.component';


@NgModule({
  declarations: [AppDragDropDirective, FormContainerComponent,
    ConfigurationDialogueComponent, GridContainerComponent, TextControlComponent, DatepickerControlComponent,
    SelectControlComponent, FileControlComponent, LookupControlComponent, ButtonControlComponent,
    CheckboxControlComponent, PopoverComponent, TemplatePreviewComponent, TextPreviewComponent,
    DatePreviewComponent, SelectPreviewComponent, CheckboxPreviewComponent, ButtonPreviewComponent,
    FilePreviewComponent, TextPropertyComponent, DatePropertyComponent, CheckboxPropertyComponent,
    FilePropertyComponent, ButtonPropertyComponent, SelectPropertyComponent, BuildingBlockPropertyComponent, TreeBlockComponent,
    ReportTemplatePropertyComponent, LookupPreviewComponent, LookupPropertyComponent, BuildingBlockSelectorDialogComponent,
    CompositeBlockContainerComponent, ReportBlockConfigDialogComponent, ConfirmationDialogComponent, OptionSelectComponent, ReportConfigurationDialogComponent,
    PublishConfirmationDialogComponent, RteBlockContainerComponent, RtePropertyComponent, RtePreviewComponent, TextAreaPreviewComponent, TextAreaPropertyComponent
  ],
  imports: [
    MaterialModule,
    CommonModule,
    NgbPopoverModule,
    NgbTabsetModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  entryComponents: [ConfigurationDialogueComponent, BuildingBlockSelectorDialogComponent, ReportBlockConfigDialogComponent, ConfirmationDialogComponent,
    ReportConfigurationDialogComponent, PublishConfirmationDialogComponent],
  exports: [
    AppDragDropDirective, FormContainerComponent, GridContainerComponent,
    TextControlComponent, DatepickerControlComponent, SelectControlComponent, FileControlComponent,
    LookupControlComponent, ButtonControlComponent, CheckboxControlComponent, NgbPopoverModule,
    TemplatePreviewComponent, TextPreviewComponent, DatePreviewComponent, SelectPreviewComponent,
    FilePreviewComponent, ButtonPreviewComponent, CheckboxPreviewComponent, TextPropertyComponent,
    SelectPropertyComponent, CheckboxPropertyComponent, ButtonPropertyComponent, DatePropertyComponent, TreeBlockComponent,
    FilePropertyComponent, BuildingBlockPropertyComponent, ReportTemplatePropertyComponent, LookupPreviewComponent,
    LookupPropertyComponent, CompositeBlockContainerComponent, OptionSelectComponent, RteBlockContainerComponent,
    RtePreviewComponent, RtePropertyComponent, TextAreaPreviewComponent, TextAreaPropertyComponent
  ]
})
export class SharedModule {
}
