import {Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';

@Directive({
  selector: '[appDragDrop]'
})
export class AppDragDropDirective implements OnInit, OnDestroy{

  @Input() data: any;
  @Input() dropZone = false;

  @Output() frwDragStart: EventEmitter<any> = new EventEmitter();
  @Output() frwDropped: EventEmitter<DragEvent> = new EventEmitter();

  constructor(private readonly renderer: Renderer2, private readonly elementRef: ElementRef) { }

  /**
   * The function is called when angular init is called so that we can set the draggable attribute to that control
   */
  ngOnInit(): void {
    if (!this.dropZone) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'draggable', 'true');
    }
  }

  /**
   * Host listener for drag start
   */
  @HostListener('dragstart', ['$event']) onDragStart(evt: DragEvent) {
    evt.dataTransfer.dropEffect = 'copy';
    evt.dataTransfer.setData('draggedItem', JSON.stringify(this.data));
    this.frwDragStart.emit(this.data);
  }

  /**
   * Host listener for drag over handler
   */
  @HostListener('dragover', ['$event']) onDragOverHandler(evt: DragEvent) {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = 'copy';
  }

  /**
   * Host listener for drop handler
   */
  @HostListener('drop', ['$event']) onDropHandler(evt: DragEvent) {
    evt.preventDefault();
    this.frwDropped.emit(evt);
  }

  /**
   * The function is called when on destroy method is called by angular lifecycle
   */
  ngOnDestroy(): void {
    this.data = null;
  }

}
