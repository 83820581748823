import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { Subscription } from 'rxjs';
import { BasePropertyComponent } from 'src/app/shared/components/property-panels/base/base-property.component';
import { ControlModel } from 'src/app/core/models/control.model';
import { MessagingService } from 'src/app/core/services/messaging.service';
import { CreateBuildingService } from 'src/app/core/services/create-building.service';
import { FieldOptionsService } from 'src/app/core/services/field-options.service';

@Component({
    selector: 'app-button-property',
    templateUrl: './button-property.component.html',
    styleUrls: ['./button-property.component.scss']
})
export class ButtonPropertyComponent extends BasePropertyComponent
    implements OnInit, OnDestroy {
    @Input() isExpanded: boolean;
    public selectedType: string;
    public buttonPropertyGroup: FormGroup;
    private optionSubscription: Subscription;
    selectionTypes: Array<string> = ['user', 'system'];

    constructor(
        private messageService: MessagingService,
        private createBuildingService: CreateBuildingService,
        private fieldOptionsService: FieldOptionsService
    ) {
        super();
    }

    ngOnInit() {
        super.selectFieldControl(this.createBuildingService);
        this.buttonPropertyGroup = new FormGroup({
            nodeName: new FormControl(
                this.controlInstance.attributes.node_name,
                Validators.required
            ),
            actionType: new FormControl(
                this.controlInstance['action_type'],
                Validators.required
            ),
            dbColumn: new FormControl(
                this.controlInstance.db_column,
                Validators.required
            )
        });
        this.removeProperty();
        this.createBuildingService.buildingBlockGroup.addControl(
            'buttonProperty',
            this.buttonPropertyGroup
        );
        this.onChanges();
    }

    /**
     * This method is used to remove the property which is not required for this control
     */
    removeProperty() {
        delete this.controlInstance.languageInfo;
        delete this.controlInstance.source;
        delete this.controlInstance.isEditable;
        delete this.controlInstance.attributes.min;
        delete this.controlInstance.attributes.max;
        delete this.controlInstance.attributes.required;
        delete this.controlInstance.attributes.readonly;
        delete this.controlInstance.attributes.node_full_path;
    }

    /**
     * This method is used to add listener on form group to detect any changes.
     */
    onChanges() {
        this.buttonPropertyGroup.valueChanges.subscribe((value) => {
            this.updateState();
        });
    }

    /**
     * This method is used to change in the selection of action type in select control.
     * @param event
     */
    onSelectionChange(event: MatSelectChange) {
        if (this.selectedType === 'user') {
            this.addUserControls();
            this.buttonPropertyGroup.removeControl('dbColumn');
        } else {
            this.buttonPropertyGroup.addControl(
                'dbColumn',
                new FormControl(
                    this.controlInstance.db_column,
                    Validators.required
                )
            );
            this.removeUserControls();
        }
    }

    /**
     * This method is used to add additional control to form group in case of user action type
     */
    addUserControls() {
        this.buttonPropertyGroup.addControl(
            'data',
            new FormControl(
                this.controlInstance.attributes['data'],
                Validators.required
            )
        );
        this.buttonPropertyGroup.addControl(
            'lambdaName',
            new FormControl(
                this.controlInstance.attributes['lambdaName'],
                Validators.required
            )
        );
        this.buttonPropertyGroup.addControl(
            'name',
            new FormControl(
                this.controlInstance.attributes['name'],
                Validators.required
            )
        );
        this.buttonPropertyGroup.addControl(
            'refreshData',
            new FormControl(
                this.controlInstance.attributes['refreshData'],
                Validators.required
            )
        );
        this.buttonPropertyGroup.addControl(
            'sendData',
            new FormControl(
                this.controlInstance.attributes['sendData'],
                Validators.required
            )
        );
        this.buttonPropertyGroup.addControl(
            'typeData',
            new FormControl(
                this.controlInstance.attributes['type'],
                Validators.required
            )
        );
        this.buttonPropertyGroup.addControl(
            'mode',
            new FormControl(
                this.controlInstance.attributes['mode'],
                Validators.required
            )
        );
        this.buttonPropertyGroup.addControl(
            'selectionTypes',
            new FormControl(
                this.controlInstance['action_type'],
                Validators.required
            )
        );
    }

    /**
     * This method is used to remove controls from form array related to user action.
     */
    removeUserControls() {
        this.buttonPropertyGroup.removeControl('data');
        this.buttonPropertyGroup.removeControl('lambdaName');
        this.buttonPropertyGroup.removeControl('name');
        this.buttonPropertyGroup.removeControl('refreshData');
        this.buttonPropertyGroup.removeControl('sendData');
        this.buttonPropertyGroup.removeControl('typeData');
        this.buttonPropertyGroup.removeControl('mode');
    }

    /**
     * This method is used to update state of the control in the common state.
     */
    updateState() {
        if (this.buttonPropertyGroup.valid) {
            this.controlInstance.attributes.node_name = this.buttonPropertyGroup.get(
                'nodeName'
            ).value;
            this.controlInstance['action_type'] = this.buttonPropertyGroup.get(
                'actionType'
            ).value;
            if (this.selectedType === 'user') {
                this.updateUserTypeButtonProperty();
            } else {
                this.controlInstance.db_column = this.buttonPropertyGroup.get(
                    'dbColumn'
                ).value;
            }
            this.createBuildingService.buildingBlockGroup.removeControl(
                'buttonProperty'
            );
            this.createBuildingService.buildingBlockGroup.addControl(
                'buttonProperty',
                this.buttonPropertyGroup
            );
            this.createBuildingService.updateControl(this.controlInstance);
        }
    }

    /**
     * This method is used to update property based on the button action type
     */
    updateUserTypeButtonProperty() {
        this.controlInstance.attributes['data'] = this.buttonPropertyGroup
            .get('data')
            .value();
        this.controlInstance.attributes[
            'lambdaName'
        ] = this.buttonPropertyGroup.get('lambdaName').value();
        this.controlInstance.attributes['mode'] = this.buttonPropertyGroup
            .get('mode')
            .value();
        this.controlInstance.attributes['name'] = this.buttonPropertyGroup
            .get('name')
            .value();
        this.controlInstance.attributes[
            'refreshData'
        ] = this.buttonPropertyGroup.get('refreshData').value();
        this.controlInstance.attributes[
            'sendData'
        ] = this.buttonPropertyGroup.get('sendData').value();
        this.controlInstance.attributes['type'] = this.buttonPropertyGroup
            .get('typeData')
            .value();
    }

    ngOnDestroy(): void {
        this.controlInstance = null;
    }
}
