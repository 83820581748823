import { environment } from 'src/environments/environment';

// base api url
const { apiUrl } = environment;

// Urls for API
export const API_URL = {
  BLOCK: apiUrl + '/block',
  BLOCKS: apiUrl + '/blocks',
  REPORT: apiUrl + '/report',
  REPORTS: apiUrl + '/reports',
  FIELDS_OPTIONS: apiUrl + '/fields-options',
};

export const ENV_VARIABLE = {
  'https://c7hfj5rui9.execute-api.eu-central-1.amazonaws.com/test_auth/v1/auth' : '-dev',
  'https://c6pfhpysl9.execute-api.eu-central-1.amazonaws.com/test_auth/v1/auth' : '-qa',
  'https://4332d1wsui.execute-api.eu-central-1.amazonaws.com/test_auth/v1/auth' : ''
}

export const ROLE_OPERATIONS = {
  VIEW_ADMIN_PANEL: 'View Admin Panel'
}
