import { Injectable } from '@angular/core';
import {MessagingService} from 'src/app/core/services/messaging.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  // public counter = 0;
  private loaderSubject = new Subject<any>();
  loaderState = this.loaderSubject.asObservable();
  constructor(public messageService: MessagingService) {}

  public showLoader() {
    // console.log(' ::: showloder');
    // if (this.counter === 0) {
    //   this.messageService.postMessage(Command.SHOW_LOADER, true);
    // }
    // this.counter++;
    this.loaderSubject.next({ show: true });
  }

  public hideLoader() {
    this.loaderSubject.next({ show: false });
    // console.log(' ::: hideloader');
    // if (this.counter > 0) {
    //   this.counter--;
    // }
    // if (this.counter === 0) {
    //   this.messageService.postMessage(Command.SHOW_LOADER, false);
    // }
  }
}
