import { AuthService as CoreAuthService } from '@mosaic-apps/login-auth';
import { LoaderService } from 'src/app/core/services/loader.service';
import { Injectable } from '@angular/core';
import {
  CanActivate
} from '@angular/router';
import { UserOperationService } from '@mosaic-apps/user-auth';
import { ENV_VARIABLE, ROLE_OPERATIONS } from '../../core.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  authenticated;
  constructor(
    private authService: CoreAuthService,
    private loaderService: LoaderService,
    private userOperationService: UserOperationService
  ) {
    this.authenticated = authService.isAuthenticated();
  }

  canActivate(): Promise<boolean>| boolean {
    return new Promise((resolve) => {
      this.loaderService.showLoader();
      this.authService.isAuthenticated()
      .then(autherized => {
        this.loaderService.hideLoader();
        this.authenticated = autherized;
        if(!this.authenticated) {
          this.authService.login();
          resolve(false);
        } else {
          // logic to verify the access of the user
          const email = window.localStorage.getItem('userId');
          const gid = window.localStorage.getItem('gid');
          this.userOperationService.fetchGuid().then((result) => {
            if(result.success && result.data && result.data.loggedInUserFunctionalities.includes(ROLE_OPERATIONS.VIEW_ADMIN_PANEL)) {
              resolve(true);
            } else {
              const env = ENV_VARIABLE[this.userOperationService['API_DOMAIN']] || '';
              window.location.href = 'https://src.mosaic'+ env +'.siemens-energy.cloud/';
              resolve(false);
            }
          });
        }
      })
      .catch(err => {
        resolve(false);
      });
    });
  }
}
