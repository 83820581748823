import { Injectable } from '@angular/core';
import { ModelStore } from 'src/app/core/models/model-store';

@Injectable({
  providedIn: 'root'
})
export class DataModelService {
  /**
   * Variable to store the data model
   *
   */
  public modelStore: ModelStore;

  constructor() {
    this.modelStore = new ModelStore();
  }

  /**
   * The function is used to retrieve the model based on the model id
   */
  public getModel(id: string): any {
    return this.modelStore.getModel(id);
  }

  /**
   * This method is responsible for returning disposing member variables listeners.
   */
  public disposeById(id: string): void {
    this.modelStore.disposeById(id);
  }
}

