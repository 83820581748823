import {ModelBase} from 'src/app/core/models/base/model-base';
import {BuildingBlockItemModel} from 'src/app/core/models/building-block-item.model';

export class BuildingBlockModel extends ModelBase {
  public uuid: string;

  public version = 0;

  public index;

  public name;

  public identifier;

  // tslint:disable-next-line:variable-name
  public building_block_name;

  // tslint:disable-next-line:variable-name
  public blockDisplayName;

  // tslint:disable-next-line:variable-name
  public blockDescription;

  // tslint:disable-next-line:variable-name
  public node_name;

  // tslint:disable-next-line:variable-name
  public node_full_path;

  // tslint:disable-next-line:variable-name
  public db_table;

  public children: Array<BuildingBlockModel> = [];

  public items: Array<BuildingBlockItemModel>=[];

  public business_hooks = {};

}
