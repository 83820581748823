import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { Subscription } from 'rxjs';

import {BasePropertyComponent} from 'src/app/shared/components/property-panels/base/base-property.component';
import {ReportTemplateModel} from 'src/app/core/models/report-template.model';
import {CreateBuildingService} from 'src/app/core/services/create-building.service';
import { FieldOptionsService } from 'src/app/core/services/field-options.service';

@Component({
  selector: 'app-report-template-property',
  templateUrl: './report-template-property.component.html',
  styleUrls: ['./report-template-property.component.scss']
})
export class ReportTemplatePropertyComponent extends BasePropertyComponent implements OnInit, OnChanges, OnDestroy {

  @Input() reportTemplateInstance: ReportTemplateModel;
  @Input() isExpanded: boolean;
  public reportTemplatePropertyGroup: FormGroup;
  // dynamic options
  public reportType: any[] = [];
  public nodeFullPath: any[] = [];
  private optionSubscription: Subscription;

  constructor(private createBuildingService: CreateBuildingService, private fieldOptionsService: FieldOptionsService) {
    super();
  }

  ngOnInit() {
    this.optionSubscription = this.fieldOptionsService.options.subscribe(options => {
      this.reportType = options.report_type;
      this.nodeFullPath = options.node_full_path;
    });
    this.reportTemplatePropertyGroup = this.createBuildingService.reportBlockGroup = new FormGroup({
      name: new FormControl(this.reportTemplateInstance.type, Validators.required),
      reportType: new FormControl(this.reportTemplateInstance.report_type, Validators.required),
      nodePath: new FormControl(this.reportTemplateInstance.node_full_path, Validators.required)
    });
    this.onChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  /**
   * This method is used to add listener on form group to detect any changes.
   */
  onChanges() {
    this.reportTemplatePropertyGroup.valueChanges.subscribe(value => {
      this.updateState();
    });
  }

  /**
   * This method is used to update state of the control in the common state.
   */
  updateState() {
    if (this.reportTemplatePropertyGroup.valid) {
      this.reportTemplateInstance.type = this.reportTemplatePropertyGroup.get('name').value;
      this.reportTemplateInstance.report_type = this.reportTemplatePropertyGroup.get('reportType').value;
      this.reportTemplateInstance.node_full_path = this.reportTemplatePropertyGroup.get('nodePath').value;
      this.createBuildingService.updateReportTemplate(this.reportTemplateInstance);
    }
  }

  ngOnDestroy(): void {
    this.reportTemplateInstance = null;
    this.optionSubscription.unsubscribe();
  }
}

