import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BuildingBlockModel} from 'src/app/core/models/building-block.model';


@Injectable({
  providedIn: 'root'
})
export class BlockListNodeService {
  dataChange = new BehaviorSubject<BuildingBlockModel[]>([]);

  get data(): BuildingBlockModel[] { return this.dataChange.value; }

  constructor() {
    // this.initialize();
  }

  /**
   * This method is used to initialize the service with data which need to be rendered.
   * @param buildingBlockList
   */
  initialize(buildingBlockList: Array<BuildingBlockModel>) {
    this.dataChange.next(buildingBlockList);
  }

  /**
   * This method is used to add insert item in tree node.
   * @param parent
   * @param name
   */
  insertItem(parent: BuildingBlockModel, name: BuildingBlockModel): BuildingBlockModel {
    if (!parent.children) {
      parent.children = [];
    }
    const newItem =  name;

    parent.children.push(newItem);
    newItem.index = `${parent.index}.${parent.children.length}`;
    this.dataChange.next(this.data);
    return newItem;
  }

  /**
   * This method is used to add an item to tree node.
   * @param node
   * @param name
   */
  insertItemAbove(node: BuildingBlockModel, name: BuildingBlockModel): BuildingBlockModel {
    const parentNode = this.getParentFromNodes(node);
    const newItem = name;
    if (parentNode != null) {
      parentNode.children.splice(parentNode.children.indexOf(node), 0, newItem);
    } else {
      this.data.splice(this.data.indexOf(node), 0, newItem);
    }
    this.dataChange.next(this.data);
    return newItem;
  }

  /**
   * This method is used to add item below.
   * @param node
   * @param name
   */
  insertItemBelow(node: BuildingBlockModel, name: BuildingBlockModel): BuildingBlockModel {
    const parentNode = this.getParentFromNodes(node);
    const newItem = name;
    if (parentNode != null) {
      parentNode.children.splice(parentNode.children.indexOf(node) + 1, 0, newItem);
    } else {
      this.data.splice(this.data.indexOf(node) + 1, 0, newItem);
    }
    this.dataChange.next(this.data);
    return newItem;
  }

  /**
   * This method is used to get parent nodes for the node selected.
   * @param node
   */
  getParentFromNodes(node: BuildingBlockModel): BuildingBlockModel {
    let parent = null;
    this.data.forEach(item => {
      const currentRoot = item;
      parent = this.getParent(currentRoot, node);
      if (parent != null) {
        return parent;
      }
    });
    return parent;
  }

  /**
   * This methods is used to add parent.
   * @param currentRoot
   * @param node
   */
  getParent(currentRoot: BuildingBlockModel, node: BuildingBlockModel): BuildingBlockModel {
    let parent = null;
    if (currentRoot.children && currentRoot.children.length > 0) {
      currentRoot.children.forEach(item => {
        const child = item;
        if (child === node) {
          return currentRoot;
        } else if (child.children && child.children.length > 0) {
          parent = this.getParent(child, node);
        }
      });
    }
    return parent;
  }

  /**
   * This method is used to update item.
   * @param node
   * @param name
   */
  updateItem(node: BuildingBlockModel, name: string) {
    node.name = name;
    this.dataChange.next(this.data);
  }

  /**
   * This method is used to delete the node
   * @param node
   */
  deleteItem(node: BuildingBlockModel) {
    this.deleteNode(this.data, node);
    this.dataChange.next(this.data);
  }

  /**
   * This method is used to insert item on tree node
   * @param from
   * @param to
   */
  copyPasteItem(from: BuildingBlockModel, to: BuildingBlockModel): BuildingBlockModel {
    const newItem = this.insertItem(to, from);
    if (from.children) {
      from.children.forEach(child => {
        this.copyPasteItem(child, newItem);
      });
    }
    return newItem;
  }

  /**
   * This method is used to add item above the tree node.
   * @param from
   * @param to
   */
  copyPasteItemAbove(from: BuildingBlockModel, to: BuildingBlockModel): BuildingBlockModel {
    const newItem = this.insertItemAbove(to, from);
    if (from.children) {
      from.children.forEach(child => {
        this.copyPasteItem(child, newItem);
      });
    }
    return newItem;
  }

  /**
   * This method is used to add item below to the tree node.
   * @param from
   * @param to
   */
  copyPasteItemBelow(from: BuildingBlockModel, to: BuildingBlockModel): BuildingBlockModel {
    const newItem = this.insertItemBelow(to, from);
    if (from.children) {
      from.children.forEach(child => {
        this.copyPasteItem(child, newItem);
      });
    }
    return newItem;
  }

  /**
   * This method is used to delete the tree node.
   * @param nodes
   * @param nodeToDelete
   */
  deleteNode(nodes: BuildingBlockModel[], nodeToDelete: BuildingBlockModel) {
    const index = nodes.indexOf(nodeToDelete, 0);
    if (index > -1) {
      nodes.splice(index, 1);
    } else {
      nodes.forEach(node => {
        if (node.children && node.children.length > 0) {
          this.deleteNode(node.children, nodeToDelete);
        }
      });
    }
  }
}
