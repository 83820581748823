import {BuildingBlockModel} from 'src/app/core/models/building-block.model';
import {ModelBase} from 'src/app/core/models/base/model-base';

export class ReportTemplateModel extends ModelBase {

  public version;

  public isValid = true;

  // tslint:disable-next-line:variable-name
  public node_full_path;

  // tslint:disable-next-line:variable-name
  public report_type;

  // tslint:disable-next-line:variable-name
  public type;

  // tslint:disable-next-line:variable-name
  public building_block_list: Array<BuildingBlockModel> = [];
}
