import {MatDialog, MatDialogRef} from '@angular/material';
import {Subscription} from 'rxjs';
import {Input} from '@angular/core';

import {ConfigurationDialogueComponent} from 'src/app/shared/components/dialog/configuration-dialogue/configuration-dialogue.component';
import {ControlModel} from 'src/app/core/models/control.model';
import {MessagingService} from 'src/app/core/services/messaging.service';
import {Command} from 'src/app/core/enums/command.enum';

export class BasePreviewComponent {
  public title = '';
  @Input() controlModel: ControlModel;
  @Input() isConfigurable: boolean;
  @Input() itemIndex: number;
  @Input() isSelected: boolean;
  @Input() gridMode: boolean;
  private subscription: Subscription;
  private matDialog: MatDialog;

  constructor(isSelected: boolean, matDialog: MatDialog) {
    this.isSelected = isSelected;
    this.matDialog = matDialog;
  }

  init(): void {
    this.subscription = new Subscription();
  }

  destroy(): void {
    this.subscription.unsubscribe();
  }

  onSelect(messageService: MessagingService): void {
    const commandType = this.gridMode ? Command.GRID_CONTROL_SELECTED : Command.CONTROL_SELECTED;
    messageService.postMessage(commandType, {index: this.itemIndex, control: this.controlModel});
  }

  openConfigurationPopup(payload: any): any {
    const newPayload: any = JSON.parse(JSON.stringify(payload));
    const dialogRef: MatDialogRef<ConfigurationDialogueComponent> = this.matDialog.open(ConfigurationDialogueComponent, {
      data: {
        config: newPayload
      },
      minWidth: '600px',
      disableClose: true,
      autoFocus: false
    });
    this.subscription.add(dialogRef.afterClosed().subscribe((data: ControlModel) => {
      if (data) {
        Object.assign(payload, data);
      }
    }));
  }
}
