import { AuthGuard } from './core/services/guard/auth.guard';
import {
    Routes,
    RouterModule,
    CanLoad,
    Route,
    UrlSegment
} from '@angular/router';
import { Injectable, isDevMode, NgModule } from '@angular/core';
import {
    ImplicitCallbackComponent,
    AuthInterceptorService
} from '@mosaic-apps/login-auth';
import { Observable } from 'rxjs';
import {
    DataGroupsComponent,
    RolesComponent,
    UsersComponent,
    UserDetailsComponent,
    UserProfileComponent,
    ProjectContactsComponent
} from '@mosaic-apps/user-auth';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@Injectable()
export class DevEnvGuard implements CanLoad {
    constructor() {}

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        return isDevMode();
    }
}

export const routes: Routes = [
    {
        path: 'home',
        loadChildren: './app-modules/home/home.module#HomeModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'manage/users',
        component: UsersComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'manage/projectcontacts',
        component: ProjectContactsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'manage/users/add',
        component: UserDetailsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'manage/users/edit',
        component: UserDetailsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'manage/roles',
        component: RolesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'manage/users/profile',
        component: UserProfileComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'manage/datagroups',
        component: DataGroupsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'block',
        loadChildren:
            './app-modules/building-block/building-block.module#BuildingBlockModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'report',
        loadChildren:
            './app-modules/report-template/report-template.module#ReportTemplateModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'workflow',
        loadChildren: './app-modules/workflow/workflow.module#WorkflowModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'implicit/callback',
        component: ImplicitCallbackComponent,
        data: { redirectURL: 'home' }
    },
    { path: '**', redirectTo: 'home' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
