import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {NotificationService} from '../notification.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(public notificationService: NotificationService, 
    public authService: AuthService) { }

  /**
   * The function is used to intercept the request and response and log the error in case of any.
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(error => {
      this.authService.isAuthenticated().then((res) => {
          if(res) {
            if(error.url.includes('user-gid')) {
              this.authService.login();
            }
          }
      });
      const message = navigator.onLine ?  error.error.error : 'No Internet Connection';
      // this.notificationService.danger(message);
      return throwError(error);
    }));
  }
}
