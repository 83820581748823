import {Input} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {Subscription} from 'rxjs';

import {MessagingService} from 'src/app/core/services/messaging.service';
import {Command} from 'src/app/core/enums/command.enum';
import {ConfigurationDialogueComponent} from 'src/app/shared/components/dialog/configuration-dialogue/configuration-dialogue.component';
import {ControlModel} from 'src/app/core/models/control.model';

export class BaseControl {
  @Input() isConfigurable: boolean;
  @Input() itemIndex: object;
  @Input() isSelected: boolean;
  private matDialog: MatDialog;
  private subscription: Subscription;

  constructor(isSelected: boolean, matDialog: MatDialog) {
    this.isSelected = isSelected;
    this.matDialog = matDialog;
  }

  init(): void {
    this.subscription = new Subscription();
  }

  destroy(): void {
    this.subscription.unsubscribe();
  }

  onSelect(messageService: MessagingService): void {
    if (this.isConfigurable) {
      messageService.postMessage(Command.CONTROL_SELECTED, this.itemIndex);
    }
  }

  openConfigurationPopup(payload: any): any {
    const newPayload: any = JSON.parse(JSON.stringify(payload));
    const dialogRef: MatDialogRef<ConfigurationDialogueComponent> = this.matDialog.open(ConfigurationDialogueComponent, {
      data: {
        config: newPayload
      },
      minWidth: '600px',
      disableClose: true,
      autoFocus: false
    });
    this.subscription.add(dialogRef.afterClosed().subscribe((data: ControlModel) => {
      if (data) {
        Object.assign(payload, data);
      }
    }));
  }
}
