import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material';

import {BuildingBlockItemModel} from 'src/app/core/models/building-block-item.model';
import {ControlModel} from 'src/app/core/models/control.model';
import {ConfigurationDialogueComponent} from 'src/app/shared/components/dialog/configuration-dialogue/configuration-dialogue.component';

@Component({
  selector: 'app-form-container',
  templateUrl: './form-container.component.html',
  styleUrls: ['./form-container.component.scss']
})

export class FormContainerComponent implements OnInit, OnDestroy {

  @Input() public section: BuildingBlockItemModel;
  @Output() public controlsAdded: EventEmitter<ControlModel> = new EventEmitter();
  @Input() public controlSelected: number;
  @Input() private containerIndex: number;
  public dragHelperText = 'Drag your control here from left';
  private subscription: Subscription;

  constructor(public matDialog: MatDialog) {
  }

  ngOnInit() {
    this.subscription = new Subscription();
  }

  /**
   * The function is used to open configuration popover
   */
  openConfigurationPopup(payload: any): any {
    const dialogRef: MatDialogRef<ConfigurationDialogueComponent> = this.matDialog.open(ConfigurationDialogueComponent, {
      data: {
        config: payload,
        type: 'item'
      },
      minWidth: '600px',
      disableClose: true,
      autoFocus: false
    });
    this.subscription.add(dialogRef.afterClosed().subscribe((data: ControlModel) => {
      if (data) {
        this.section.controls.push(data);
        this.controlsAdded.emit(data);
      }
    }));
  }

  /**
   * The function is called when any item is dropped onto the drop region
   */
  onItemDropped(event: Event) {
    event.stopPropagation();
    let data: any = (event as DragEvent).dataTransfer.getData('draggedItem');
    data = JSON.parse(data);
    if (data.parent === this.section.type) {
      this.openConfigurationPopup(data);
    }
  }

  /**
   * The method is called on onDestroy
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.controlsAdded = null;
    this.section.controls = [];
  }

}
