import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { BasePropertyComponent } from 'src/app/shared/components/property-panels/base/base-property.component';
import { ControlModel } from 'src/app/core/models/control.model';
import { CreateBuildingService } from 'src/app/core/services/create-building.service';
import { FieldOptionsService } from 'src/app/core/services/field-options.service';

@Component({
    selector: 'app-select-property',
    templateUrl: './select-property.component.html',
    styleUrls: ['./select-property.component.scss']
})
export class SelectPropertyComponent extends BasePropertyComponent
    implements OnInit, OnChanges, OnDestroy {
    @Input() controlInstance: ControlModel;
    @Input() isExpanded: boolean;
    public selectPropertyGroup: FormGroup;
    public dataInfoTypes = ['function', 'view'];
    public dataInfoSelectedType = 'function';
    private optionSubscription: Subscription;
    public optionArray: any;
    public type: string;
    // field col names
    public fieldsColNames: any[] = [];

    constructor(
        private createBuildingService: CreateBuildingService,
        private fieldOptionsService: FieldOptionsService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.selectPropertyGroup) {
            this.forceUpdate();
        }
    }

    ngOnInit() {
        this.type = this.controlInstance.source
            ? this.controlInstance.source.type
            : '';
        super.selectFieldControl(this.createBuildingService);
        this.selectPropertyGroup = new FormGroup({
            dbColumn: new FormControl(null, Validators.required),
            identifier: new FormControl(null),
            isEditable: new FormControl(null),
            required: new FormControl(null),
            readOnly: new FormControl(null),
            nodeName: new FormControl(null, Validators.required)
        });
        this.forceUpdate();
        this.removeProperty();
        this.createBuildingService.buildingBlockGroup.addControl(
            'selectProperty',
            this.selectPropertyGroup
        );
        this.onChanges();
    }

    /**
     * This will update the view.
     */
    forceUpdate() {
        this.type = this.controlInstance.source
            ? this.controlInstance.source.type
            : '';
        this.selectPropertyGroup
            .get('dbColumn')
            .setValue(this.controlInstance.db_column);
        this.selectPropertyGroup
            .get('identifier')
            .setValue(this.controlInstance['identifier']);
        this.selectPropertyGroup
            .get('isEditable')
            .setValue(this.controlInstance.isEditable);
        this.selectPropertyGroup
            .get('required')
            .setValue(this.controlInstance.attributes.required);
        this.selectPropertyGroup
            .get('readOnly')
            .setValue(this.controlInstance.attributes.readonly);
        this.selectPropertyGroup
            .get('nodeName')
            .setValue(this.controlInstance.attributes.node_name);
        this.addValidationOnType();
    }

    /**
     * Method is used to remove extra property
     */
    removeProperty() {
        delete this.controlInstance.languageInfo;
        delete this.controlInstance.attributes.name;
        if (this.type !== 'dynamic') {
            delete this.controlInstance.source.dataInfo;
            delete this.controlInstance.source.params;
        } else {
            delete this.controlInstance.source.options;
        }
    }

    /**
     * This method add validation on the basis of type of select.
     */
    addValidationOnType() {
        if (this.type === 'dynamic' || this.type === 'cascading') {
            this.addDynamicControl();
        } else {
            this.removeDynamicControl();
            this.optionArray = this.controlInstance.source.options;
        }
    }

    /**
     * Method is used to add control required for the dynamic select control.
     */
    addDynamicControl() {
        this.selectPropertyGroup.addControl(
            'dataInfo',
            new FormControl(
                this.controlInstance.source.dataInfo.name,
                Validators.required
            )
        );
        this.selectPropertyGroup.addControl(
            'dataInfoType',
            new FormControl(
                this.controlInstance.source.dataInfo.name,
                Validators.required
            )
        );
        if (this.type === 'cascading') {
            this.selectPropertyGroup.addControl(
                'linkChildId',
                new FormControl(
                    this.controlInstance['linkChildId'],
                    Validators.required
                )
            );
        }
        this.selectPropertyGroup.addControl(
            'parentBuildingBlock',
            new FormControl(
                this.controlInstance.source.params[0]
                    ? this.controlInstance.source.params[0][
                          'parent_building_block'
                      ]
                    : null,
                Validators.required
            )
        );
        this.selectPropertyGroup.addControl(
            'parentControlName',
            new FormControl(
                this.controlInstance.source.params[1]
                    ? this.controlInstance.source.params[1][
                          'parent_control_name'
                      ]
                    : null,
                Validators.required
            )
        );
    }

    /**
     * Method is used to remove dynamic control if required.
     */
    removeDynamicControl() {
        if (this.type === 'cascading') {
            this.selectPropertyGroup.removeControl('linkChildId');
        }
        this.selectPropertyGroup.removeControl('dataInfo');
        this.selectPropertyGroup.removeControl('dataInfoType');
        this.selectPropertyGroup.removeControl('parentBuildingBlock');
        this.selectPropertyGroup.removeControl('parentControlName');
    }

    /**
     * This method is used to add listener on form group to detect any changes.
     */
    onChanges() {
        this.selectPropertyGroup.valueChanges.subscribe((value) => {
            this.updateState();
        });
    }

    /**
     * This method is used to update state of the control in the common state.
     */
    updateState() {
        if (this.selectPropertyGroup.valid) {
            this.controlInstance.db_column = this.selectPropertyGroup.get(
                'dbColumn'
            ).value;
            this.controlInstance['identifier'] = this.selectPropertyGroup.get(
                'identifier'
            ).value;
            this.controlInstance.attributes.node_name = this.selectPropertyGroup.get(
                'nodeName'
            ).value;
            this.controlInstance.attributes.required = this.selectPropertyGroup.get(
                'required'
            ).value;
            this.controlInstance.attributes.readonly = this.selectPropertyGroup.get(
                'readOnly'
            ).value;
            if (this.type === 'dynamic') {
                this.controlInstance.source.params = [];
                this.controlInstance.source.dataInfo.type = this.selectPropertyGroup.get(
                    'dataInfo'
                ).value;
                this.controlInstance.source.dataInfo.name = this.selectPropertyGroup.get(
                    'dataInfoType'
                ).value;
                this.controlInstance.source.params.push({
                    parent_building_block: this.selectPropertyGroup.get(
                        'parentBuildingBlock'
                    ).value
                });
                this.controlInstance.source.params.push({
                    parent_control_name: this.selectPropertyGroup.get(
                        'parentControlName'
                    ).value
                });
            }
            if (this.type === 'dynamic') {
                this.controlInstance[
                    'linkedChildId'
                ] = this.selectPropertyGroup.get('linkedChildId');
            }
            this.createBuildingService.buildingBlockGroup.removeControl(
                'selectProperty'
            );
            this.createBuildingService.buildingBlockGroup.addControl(
                'selectProperty',
                this.selectPropertyGroup
            );
            this.createBuildingService.updateControl(this.controlInstance);
        }
    }

    /**
     * This method is used to add option in control instance.
     * @param nameVal
     * @param typeValue
     */
    addOption(nameVal: any, typeValue: any) {
        const name = nameVal.value;
        const type = typeValue.value;
        if (name != '' && type != '') {
            this.controlInstance.source.options.push({
                displayName: name,
                value: type
            });
        }
        nameVal.value = '';
        typeValue.value = '';
    }

    /**
     * This method is used to update the option array
     * @param event
     * @param type
     * @param index
     */
    optionChanged(event: any, type: string, index: number) {
        if (event.currentTarget.value !== '') {
            this.controlInstance.source.options[index][type] =
                event.currentTarget.value;
        }
    }

    ngOnDestroy(): void {
        this.controlInstance = null;
    }
}
