import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatDialog, MatDialogRef, MatSnackBar, MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material';
import {Subscription} from "rxjs";
import {Control} from "src/app/core/enums/control.enum";

import {BlockItemFlatNode} from 'src/app/core/models/block-item-flat-node';
import {BuildingBlockModel} from 'src/app/core/models/building-block.model';
import {BlockListNodeService} from 'src/app/core/services/block-list-node.service';
import {ReportTemplateModel} from 'src/app/core/models/report-template.model';
import {MessagingService} from 'src/app/core/services/messaging.service';
import {Command} from 'src/app/core/enums/command.enum';
import {ReportBlockConfigDialogComponent} from "src/app/shared/components/dialog/report-block-config-dialog/report-block-config-dialog.component";

@Component({
  selector: 'app-tree-block',
  templateUrl: './tree-block.component.html',
  styleUrls: ['./tree-block.component.scss']
})
export class TreeBlockComponent implements OnChanges {

  @Input() canDrop = false;
  // report list
  @Input() reportTemplateModel: ReportTemplateModel;
  /* Drag and drop */
  @Input() dragNodeMenu: any;
  public treeControl: FlatTreeControl<BlockItemFlatNode>;
  public dataSource: MatTreeFlatDataSource<any, BlockItemFlatNode>;

  private buildBlockFlattener: MatTreeFlattener<BuildingBlockModel, BlockItemFlatNode>;
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  private flatNodeMap = new Map<BlockItemFlatNode, BuildingBlockModel>();
  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  private nestedNodeMap = new Map<BuildingBlockModel, BlockItemFlatNode>();
  // @ts-ignore
  @ViewChild('emptyItem') emptyItem: ElementRef;
  private dragNode: any;
  private dragNodeExpandOverWaitTimeMs = 300;
  private dragNodeExpandOverNode: any;
  private dragNodeExpandOverTime: number;
  private dragNodeExpandOverArea: string;
  private subscription: Subscription;

  constructor( private blockNodeService: BlockListNodeService, private messageService: MessagingService, private snackBar: MatSnackBar,
               public matDialog: MatDialog) {
    this.initializeTree();
    blockNodeService.dataChange.subscribe(data => {
      this.dataSource.data = [];
      if (data) {
        data = this.addIndex(data, '');
        this.dataSource.data = data;
      }
    });
    this.subscription = new Subscription();
  }

  /**
   * Method is used to show configuration dialog for the building block which need to be add
   * in report template.
   * @param data
   * @param node
   */
  openConfigurationDialog(data: BuildingBlockModel, node: any) {
    const dialogRef: MatDialogRef<ReportBlockConfigDialogComponent> = this.matDialog.open(ReportBlockConfigDialogComponent, {
      data,
      minWidth: '600px',
      disableClose: true
    });
    this.subscription.add(dialogRef.afterClosed().subscribe((data: BuildingBlockModel) => {
      if(data) {
        this.arrangeTree(this.transformer(data, node.level), node);
      }
    }));
  }

  /**
   * This method is used to add indexes in array provided
   * @param listArray: instance of list array
   * @param preIndex: previous index
   */
  addIndex(listArray: Array<BuildingBlockModel>, preIndex: any) {
    // const copy: Array<BuildingBlockModel> = JSON.parse(JSON.stringify(listArray));
    listArray.map((item, index) => {
      item.index = `${preIndex}${++index}`;
      if(item.children && item.children.length > 0) {
        item.children = this.addIndex(JSON.parse(JSON.stringify(item.children)), `${item.index}.`);
      }
      return item;
    });
    return listArray;
  }

  getLevel = (node: BlockItemFlatNode) => node.level;

  isExpandable = (node: BlockItemFlatNode) => node.expandable;

  getChildren = (node: BuildingBlockModel): BuildingBlockModel[] => node.children;

  // tslint:disable-next-line:variable-name
  hasChild = (_: number, _nodeData: BlockItemFlatNode) => _nodeData.expandable;

  /**
   * This method is used to initialize tree control.
   */
  initializeTree() {
    this.buildBlockFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<BlockItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.buildBlockFlattener);
  }

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: BuildingBlockModel, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.uuid
      ? existingNode
      : new BlockItemFlatNode();
    flatNode.item = node.blockDisplayName;
    flatNode.level = level;
    flatNode.uuid = node.uuid;
    flatNode.index = node.index;
    flatNode.type = node['type'] ? node['type'] : '';
    flatNode.expandable = (node.children && node.children.length > 0 && node['type'] !== Control.COMPOSITE_BLOCK);
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      console.log(changes);
    }
  }

  /**
   * This method is used to handle drag event within tree.
   * @param event: drag event occur when dragging event tree.
   * @param node: tree node which is dragged.
   */
  handleDragStart(event, node) {
    // Required by Firefox (https://stackoverflow.com/questions/19055264/why-doesnt-html5-drag-and-drop-work-in-firefox)
    event.dataTransfer.setData('foo', 'bar');
    event.dataTransfer.setDragImage(this.emptyItem.nativeElement, 0, 0);
    this.dragNode = node;
    this.treeControl.collapse(node);
  }

  /**
   * This method is used to handle drag over event in tree control.
   * @param event: drag event with tree.
   * @param node: node on which dragged over occurred.
   */
  handleDragOver(event, node) {
    event.preventDefault();
    // Handle node expand
    if (node === this.dragNodeExpandOverNode) {
      if (this.dragNode !== node && !this.treeControl.isExpanded(node)) {
        if ((new Date().getTime() - this.dragNodeExpandOverTime) > this.dragNodeExpandOverWaitTimeMs) {
          this.treeControl.expand(node);
        }
      }
    } else {
      this.dragNodeExpandOverNode = node;
      this.dragNodeExpandOverTime = new Date().getTime();
    }

    // Handle drag area
    const percentageY = event.offsetY / event.target.clientHeight;
    if (percentageY < 0.25) {
      this.dragNodeExpandOverArea = 'above';
    } else if (percentageY > 0.75) {
      this.dragNodeExpandOverArea = 'below';
    } else {
      this.dragNodeExpandOverArea = 'center';
    }
  }

  /**
   * This method is used to handle drag drop event.
   * @param event: Instance of drag event.
   * @param node: Tree node on which event is occurred.
   */
  handleDrop(event, node) {
    event.preventDefault();
    if (this.canDrop) {
      this.dragNodeExpandOverArea = 'below';
    }
    if (this.dragNode && node !== this.dragNode) {
      this.arrangeTree(this.dragNode, node);
    } else if (this.dragNodeMenu) {
      if(this.canDrop) {

        this.arrangeTree(this.transformer(this.dragNodeMenu, node.level), node);
      } else {
        this.openConfigurationDialog(this.dragNodeMenu, node);
      }

    }
    this.dragNodeMenu = null;
    this.dragNode = null;
    this.dragNodeExpandOverNode = null;
    this.dragNodeExpandOverTime = 0;
  }

  /**
   * This method is used to arrange tree node,
   * @param dragNode, node which is being moved
   * @param node, node on which it was dragged
   */
  arrangeTree(dragNode, node) {
    let newItem: BuildingBlockModel;
    if (this.dragNodeExpandOverArea === 'above') {
      newItem = this.blockNodeService.copyPasteItemAbove(this.flatNodeMap.get(dragNode), this.flatNodeMap.get(node));
    } else if (this.dragNodeExpandOverArea === 'below') {
      newItem = this.blockNodeService.copyPasteItemBelow(this.flatNodeMap.get(dragNode), this.flatNodeMap.get(node));
    } else {
      if (node.type === Control.COMPOSITE_BLOCK) {
        this.snackBar.open('Operation is not allowed.', 'Dismiss', {duration: 2000});
      } else {
        newItem = this.blockNodeService.copyPasteItem(this.flatNodeMap.get(dragNode), this.flatNodeMap.get(node));
      }
    }
    if(!(this.dragNodeMenu) && (node.type !== Control.COMPOSITE_BLOCK)) {
      this.blockNodeService.deleteItem(this.flatNodeMap.get(dragNode));
      this.treeControl.expandDescendants(this.nestedNodeMap.get(newItem));
    }
  }

  /**
   * This method is used to handle drag event end.
   * @param event: Instance of mouse drag event.
   */
  handleDragEnd(event) {
    this.dragNode = null;
    this.dragNodeExpandOverNode = null;
    this.dragNodeExpandOverTime = 0;
  }

  /**
   * This method is used to provide property panel selection control.
   * @param event: Instance of mouse event
   * @param node: Node on which is selected.
   */
  onSelectedBlock(event: MouseEvent, node: any) {
    event.stopImmediatePropagation();
    const blockModel: BuildingBlockModel = this.flatNodeMap.get(node);
    this.messageService.postMessage(Command.BLOCK_SELECTED, {id: -1, block: blockModel});
  }

  /**
   * This method is used to handle click on delete node button.
   * @param event: Instance of mouse event
   * @param node: Instance of tree node which is being clicked.
   */
  onTreeItemClicked(event: MouseEvent, node: any) {
    event.stopImmediatePropagation();
    this.blockNodeService.deleteItem(this.flatNodeMap.get(node));
  }

}
