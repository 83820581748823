import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { API_URL } from 'src/app/core/core.constant';
import { Control } from 'src/app/core/enums/control.enum';
import { BuildingBlockModel } from 'src/app/core/models/building-block.model';
import { JsonSerializer } from 'src/app/core/utils/json-serializer';

@Injectable({
    providedIn: 'root'
})
export class BuildingBlockService {
    private jsonSerializer: JsonSerializer;

    constructor(private httpClient: HttpClient) {
        this.jsonSerializer = new JsonSerializer();
    }

    /**
     * This method is used to create building block, this will pass block string to server.
     * @param block : string value for block
     * @param publish: boolean value to check that its publish or not
     */
    createBuildingBlock(
        block: BuildingBlockModel,
        publish = false
    ): Promise<any> {
        const blockString = this.jsonSerializer.parseBlockJson(block);
        return new Promise<any>((resolve: any) => {
            this.httpClient
                .post<any>(API_URL.BLOCK, { data: blockString, publish })
                .subscribe((data: any) => {
                    resolve(data);
                });
        });
    }

    /**
     * This method is used to update created building block.
     * @param id: string ID which we need to update building block
     * @param block: string updated building block
     * @param publish: boolean value to check that its publish or not
     */
    updateBuildingBlock(
        id: string,
        block: BuildingBlockModel,
        publish = false
    ): Promise<any> {
        const blockString = this.jsonSerializer.parseBlockJson(block);
        return new Promise<any>((resolve: any) => {
            this.httpClient
                .post<any>(API_URL.BLOCK, {
                    id: Number(id),
                    data: blockString,
                    publish
                })
                .subscribe((data: any) => {
                    resolve(data);
                });
        });
    }

    /**
     * This method is used to parse building block data which is received from server, in local format.
     */
    parseBlockData(data: any): BuildingBlockModel {
        return data.blocks.map((block) => {
            const blockItem: any = JSON.parse(block.data);
            blockItem.id = block.id;

            if (blockItem.type === Control.COMPOSITE_BLOCK) {
                blockItem.children = JSON.parse(
                    JSON.stringify(blockItem.child_items)
                );
                delete blockItem.child_items;
            }
            // Merging action control with button controls.
            if (blockItem && blockItem.items && blockItem.items[0]) {
                blockItem.items[0].actionControls = blockItem.items[0].actionControls.map(
                    (item) => {
                        return {
                            attributes: {
                                node_name: item.attributes.actionIdentifier
                            },
                            db_column: item.attributes.name,
                            type: item.type
                        };
                    }
                );
                blockItem.items[0].controls = [
                    ...blockItem.items[0].controls,
                    ...blockItem.items[0].actionControls
                ];
            }
            return blockItem;
        });
    }

    /**
     * This method is used to fetch building block with provided id, if id is null it will fetch all
     * the blocks. asd
     * @param id: number id for the building block
     */
    fetchBuildingBlock(id: number): Promise<any> {
        return new Promise<any>((resolve: any) => {
            if (id) {
                this.httpClient
                    .get<any>(`${API_URL.BLOCK}?id=${id}`)
                    .subscribe((data: any) => {
                        resolve(this.parseBlockData(data));
                    });
            } else {
                this.httpClient
                    .get<any>(API_URL.BLOCKS)
                    .subscribe((data: any) => {
                        resolve(this.parseBlockData(data));
                    });
            }
        });
    }
}
