import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSelectChange } from '@angular/material';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {Guid} from 'guid-typescript';
import {CreateBuildingService} from "src/app/core/services/create-building.service";
import { ListItemVO } from 'src/app/core/vo/list-item.vo';
import {ConfigurationModel} from 'src/app/core/models/configuration.model';
import {DataModelService} from 'src/app/core/services/data-model.service';
import {Model} from 'src/app/core/enums/model.enum';
import { FieldOptionsService } from 'src/app/core/services/field-options.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-configuration-dialogue',
  templateUrl: './configuration-dialogue.component.html',
  styleUrls: ['./configuration-dialogue.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfigurationDialogueComponent implements OnInit, OnDestroy {
  public configObject: ConfigurationModel;
  public formGroup: FormGroup;
  public selectionTypes = ['static', 'dynamic', 'cascading'];
  public selectedType = 'static';
  public selectedActionType = 'system';
  public dataInfoTypes = ['function', 'view'];
  public dataInfoSelectedType = 'function';
  private optionSubscription: Subscription;

  // field col names
  public fieldsColNames: any[] = [];

  constructor(public dialogRef: MatDialogRef<ConfigurationDialogueComponent>,
              private dataModelService: DataModelService, private buildingBlock: CreateBuildingService,
              private fieldOptionsService: FieldOptionsService,
              @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder) { }

  /**
   * The method is called on angular Init
   */
  ngOnInit() {
    if (this.data.type === 'item') {
      this.fieldsColNames = this.buildingBlock.dbColumnNameControl;
    } else {
      this.fieldsColNames = this.buildingBlock.dbColumnNameTable;
    }

    this.configObject = this.dataModelService.getModel(Model.CONFIGURATION_MODEL);
    if (this.data.config.attributes) {
      this.configObject.controlModel = this.data.config;
    } else {
      this.configObject.controlModel.type = this.data.config.type;
      if (this.configObject.controlModel.type === 'lookup' || this.configObject.controlModel.type === 'select') {
        this.configObject.controlModel.source.type = this.selectedType =
          this.configObject.controlModel.type === 'lookup' ?  'dynamic' : 'static';
      }
      this.configObject.selectedLanguage = this.configObject.languageInfo[1];
    }
    this.formGroup = this.formBuilder.group({
      nodeName : [null, Validators.required],
      labelName: [null],
      dbColumn: [null, Validators.required]
    });

    this.addControls();
  }

  /**
   * This method is used to add controls according to type.
   */
  addControls() {
    if (this.configObject.controlModel.type === 'lookup' ||
      (this.configObject.controlModel.type === 'select' && this.configObject.controlModel.source.type === 'dynamic')) {
      this.formGroup.addControl('dataInfo', new FormControl(null, Validators.required));
      this.formGroup.addControl('dataInfoType', new FormControl(null, Validators.required));
    } else if (this.configObject.controlModel.type === 'select') {
      this.formGroup.addControl('selectionTypes', new FormControl(null, Validators.required));
    } else if (this.configObject.controlModel.type === 'button') {
      this.formGroup.addControl('actionType', new FormControl(this.configObject.controlModel.attributes['action_type']));
      this.formGroup.addControl('name', new FormControl(this.configObject.controlModel.attributes['name']));
      this.formGroup.addControl('lambdaName', new FormControl(this.configObject.controlModel.attributes['lambdaName']));
    }
  }
  /**
   * This method is called when selection type is changed.
   * @param event: instance of MatSelectChange
   */
  onSelectionTypeChange(event: MatSelectChange) {
    this.selectedType = event.value;
    if (this.selectedType === 'dynamic') {
      this.formGroup.controls.dataInfo = new FormControl(null, Validators.required);
      this.formGroup.controls.dataInfoType = new FormControl(null, Validators.required);
    }
    this.configObject.controlModel.source.type = this.selectedType;
  }

  /**
   * The function is called when save is clicked
   */
  save() {
    if (this.formGroup.valid) {
      this.configObject.controlModel.languageInfo = this.configObject.languageInfo;
      if (this.configObject.controlModel.type === 'lookup' ||
        (this.configObject.controlModel.type === 'select' && this.configObject.controlModel.source.type === 'dynamic')) {
        delete this.configObject.controlModel.source.options;
        this.configObject.controlModel.source.dataInfo.name = this.formGroup.get('dataInfo').value;
        this.configObject.controlModel.source.dataInfo.type = this.formGroup.get('dataInfoType').value;
      }
      this.dialogRef.close(this.configObject.controlModel);
    } else {
      Object.keys(this.formGroup.controls).forEach(field => {
        const control = this.formGroup.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  /**
   * The function is called to replace all the numeric character with the alphabet ones
   */
  onTextChange(attributes: any, prop: string) {
    let value: string = attributes[prop];
    if (value) {
      value = value.toString();
      const regExp = /\d/g;
      value = value.replace(regExp, '');
      attributes[prop] = value;
    }
  }

  /**
   * The function is called to replace all the string character with the numeric ones
   */
  // onChange(attributes: AttributesVO, prop: string) {
  //   let value: string = attributes[prop];
  //   if (value) {
  //     value = value.toString();
  //     const regExp = /[A-Za-z]/g;
  //     value = value.replace(regExp, '');
  //     attributes[prop] = value;
  //   }
  // }

  /**
   * Set the DB column value on change
   */
  onDBColChange(val) {
    this.configObject.controlModel.db_column = val;
  }


  /**
   * The function is used to add options to the select control
   */
  addOption(selectOption: any) {
    const listItem: ListItemVO = new ListItemVO();
    listItem.displayName = Guid.create().toString();
    listItem.value = selectOption.value;
    selectOption.value = '';
    this.configObject.controlModel.source.options.push(listItem);
  }

  /**
   * The method is used to add checkbox options
   */
  // addCheckBoxOption(checkbox: any, checkboxKey: any) {
  //   const listItem: ListItemVO = new ListItemVO();
  //   listItem.displayName = checkboxKey.value;
  //   listItem.value = checkbox.checked;
  //   checkboxKey.value = '';
  //   checkbox.checked = false;
  //   this.configObject.controlModel.source.options.push(listItem);
  // }
  /**
   * The method is called when on destroy is called
   */
  ngOnDestroy(): void {
    this.configObject = null;
    this.dataModelService.disposeById(Model.CONFIGURATION_MODEL);
    // this.optionSubscription.unsubscribe();
  }

}
