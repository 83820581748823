import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material';

import {BuildingBlockItemModel} from 'src/app/core/models/building-block-item.model';
import {ControlModel} from 'src/app/core/models/control.model';
import {ConfigurationDialogueComponent} from 'src/app/shared/components/dialog/configuration-dialogue/configuration-dialogue.component';

@Component({
  selector: 'app-grid-container',
  templateUrl: './grid-container.component.html',
  styleUrls: ['./grid-container.component.scss']
})
export class GridContainerComponent implements OnInit, OnDestroy {

  @Input() public section: BuildingBlockItemModel;
  @Output() public controlsAdded: EventEmitter<{data: ControlModel, type: string}> = new EventEmitter();
  @Input() public controlSelected: number;
  @Input() private containerIndex: number;
  private subscription: Subscription;

  constructor(public matDialog: MatDialog) { }

  ngOnInit() {
    this.subscription = new Subscription();
  }

  /**
   * The function is used to open configuration popover
   */
  openConfigurationPopup(payload: any, type: string): any {
    const dialogRef: MatDialogRef<ConfigurationDialogueComponent> = this.matDialog.open(ConfigurationDialogueComponent, {
      data: {
        config: payload,
        type
      },
      minWidth: '600px',
      disableClose: true,
      autoFocus: false
    });
    this.subscription.add(dialogRef.afterClosed().subscribe((data: ControlModel) => {
      if (data) {
        if (type === 'item') {
          this.section.controls.push(data);
        } else {
          this.section.tableInfo.columns.push(data);
        }
        this.controlsAdded.emit({data, type});
      }
    }));
  }

  /**
   * The function is called when any item is dropped onto the drop region
   */
  itemDropped(event: Event) {
    event.stopPropagation();
    let data: any = (event as DragEvent).dataTransfer.getData('draggedItem');
    data = JSON.parse(data);
    if (data.parent === this.section.type) {
      this.openConfigurationPopup(data, 'item');
    }
  }

  /**
   * The function is called when any item is dropped onto the drop region
   */
  tableDropped(event: Event) {
    event.stopPropagation();
    let data: any = (event as DragEvent).dataTransfer.getData('draggedItem');
    data = JSON.parse(data);
    if (data.parent === this.section.type) {
      this.openConfigurationPopup(data, 'table');
    }
  }

  /**
   * The method is called on onDestroy
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.controlsAdded = null;
    this.section.controls = [];
  }
}
