import {Input} from "@angular/core";
import {Command} from 'src/app/core/enums/command.enum';
import {ControlModel} from "src/app/core/models/control.model";
import {CreateBuildingService} from "src/app/core/services/create-building.service";
import {MessagingService} from 'src/app/core/services/messaging.service';

export class BasePropertyComponent {
  @Input() controlInstance: ControlModel;
  // field col names
  public fieldsColNames: any[] = [];
  cancel(event: MouseEvent) {

  }

  /**
   * This method is used to init createBuilding service.
   * @param createBuildingService
   */
  selectFieldControl(createBuildingService: CreateBuildingService) {
    if (this.controlInstance.arrayGroup === 'table') {
      this.fieldsColNames = createBuildingService.dbColumnNameTable;
    } else {
      this.fieldsColNames = createBuildingService.dbColumnNameControl;
    }
  }

  /**
   * This method is used to  send save message.
   * @param data
   * @param messageService
   */
  save(data: any, messageService: MessagingService) {
    messageService.postMessage(Command.CONTROL_SAVED, data);
  }
}
